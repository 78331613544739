import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function TableCustom({ config, data }) {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table sx={{ minWidth: "100%" }} stickyHeader aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {config.map((h, i) => (
              <TableCell key={i} align={h.align}>{h.head}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {config.map((k, j) => (
                <TableCell key={j} align={k.align}> {item[k.key]} </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}