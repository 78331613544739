import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon, Add as AddIcon } from "@mui/icons-material";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import ImageGalleryCard from "components/Card/ImageLeftCard";
import { ValidationSchemaFloorFeature } from "domain/Product/services/SchemaValidation";
import { useEffect } from "react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; 
import Add from "./Add";
import Edit from "./Edit";

export default function Features({ open, close, rsdId, instance, action }) {
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit
  const [dataInstance, setDataInstance] = useState({})
  const [position, setPosition] = useState(null)
  const [instanceSelected, setInstanceSelected] = useState({})
  let { reset, control, getValues, setValue  } = useForm({
    defaultValues: { 'features': [] } 
  })
  const {fields: fTBP, append: aTBP, remove: rmvTBP, update: updTBP, move: mvTBP} = useFieldArray({ 
    name: 'features', control: control 
  })

  useEffect(() => {
    //console.log(instance)
    setDataInstance(instance)
    reset(instance)
  }, [open])

  const handleClose = () => {
    setDataInstance({})
    reset()
    close()
  }

  const handleUpdate = (params) => {
    //console.log(params)
    action(fTBP)
    handleClose()
  }

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = getValues("features");
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setValue('features', updatedList)
  }

  const Actions = () => (<>
    <Button onClick={handleClose}>Cancelar</Button>
    <Button variant="contained" color="info" onClick={() => handleUpdate()}>Actualizar</Button>
  </> )

  const handleAddFeature = (data) => { aTBP(data) }
  const handleLoadEditFeature = (position, data) => {
    setPosition(position)
    setInstanceSelected(data)
  }
  const handleEditFeature = (data) => { 
    let dataTemp = { ...fTBP[position], name: data.name, icon: data.icon }
    updTBP(position, dataTemp) 
  }
  const handleRemoveFeature = (position) => { rmvTBP(position) }
  const handlerChangePositionFeature = (direction, position) => { mvTBP(position, direction === 'up' ? position - 1 : position + 1) }
  
  const ActionsCard = ({ item, index, totalItems }) => (<>
    <IconButton aria-label="edit" onClick={ () => { handleLoadEditFeature(index, item); setOpenAction(2) }  }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => handleRemoveFeature(index) }> <DeleteIcon color='error'/> </IconButton>
    <div>
    {index !== 0 && <IconButton aria-label="up-position" onClick={ () => handlerChangePositionFeature("up", index) }> <ArrowUpwardIcon /> </IconButton>}
    {index !== totalItems - 1 && <IconButton aria-label="down-position" onClick={ () => handlerChangePositionFeature("down", index) }> <ArrowDownwardIcon /> </IconButton> }
    </div>
  </> )

  return (<>
    <FlexibleWidthDialog open={open} title="Características del piso" actions={<Actions />}>
      <IconButton autoFocus color="inherit" onClick={ () => setOpenAction(1) } aria-label="add"> <AddIcon /> </IconButton> 
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="droppable">
          { (provided) => (
            <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
            { (fTBP.length > 0) ? (
            fTBP.map((item, i) => (
              <Draggable key={item.id} draggableId={item.id} index={i}>
                {(provided) => (
                  <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                    <ImageGalleryCard image={item.icon} description={item.name} 
                      actions={ <ActionsCard item={ item } index={ i } totalItems={ fTBP.length } /> }
                    />
                  </Grid>
                )}
              </Draggable>
            )) ) : <Grid item xs={12}> <Typography variant="inherit">No hay características agregadas</Typography> </Grid> }
            </Grid>
          ) }
        </Droppable>
      </DragDropContext>
    </FlexibleWidthDialog>
    <Add open={ openAction === 1 } close={ () => setOpenAction(0) } rsdId={ rsdId } action={ handleAddFeature } />
    <Edit open={ openAction === 2 } close={ () => setOpenAction(0) } rsdId={ rsdId } instance={ instanceSelected } action={ handleEditFeature } />
  </>)
}