import { Edit as EditIcon, Delete as DeleteIcon, Image as ImageIcon, List as ListIcon, Event as EventIcon, Route as RouteIcon, Token as IconToken, Code as CodeIcon, MoreHoriz as MoreHorizIcon, SmartToy as SmartToyIcon } from '@mui/icons-material'
import { Slide } from '@mui/material';
import { forwardRef } from 'react';

export const actionsHome = [
  {
    name: "Editar",
    icon: <EditIcon color="info" sx={{ fontSize: 40 }} />,
    action: 2
  },
  {
    name: "Galería de imágenes",
    icon: <ImageIcon color="primary" sx={{ fontSize: 40 }} />,
    action: 3
  },
  {
    name: "Amenidades",
    icon: <ListIcon color="primary" sx={{ fontSize: 40 }} />,
    action: 4
  },
  {
    name: "Citas Virtuales",
    icon: <EventIcon color="primary" sx={{ fontSize: 40 }} />,
    action: 5
  },
  {
    name: "Rutas",
    icon: <RouteIcon color="primary" sx={{ fontSize: 40 }} />,
    action: 6
  },
  {
    name: "Tokens SalesUp",
    icon: <IconToken color="secondary" sx={{ fontSize: 40 }} />,
    action: 7
  },
  {
    name: "Eliminar",
    icon: <DeleteIcon color="error" sx={{ fontSize: 40 }} />,
    action: 8
  },
  {
    name: "Código",
    icon: <CodeIcon color="success" sx={{ fontSize: 40 }} />,
    action: 9
  },
  {
    name: "CRM propiedades",
    icon: <MoreHorizIcon color="success" sx={{ fontSize: 40 }} />,
    action: 12
  },
  {
    name: "Chatbot",
    icon: <SmartToyIcon color="info" sx={{ fontSize: 40 }} />,
    action: 13
  }
]

export const actionsAlttaHomes = [
  {
    name: "Editar",
    icon: <EditIcon color="info" sx={{ fontSize: 40 }} />,
    action: 10
  }
]

export const actionsHeroes = [
  {
    name: "Editar",
    icon: <EditIcon color="info" sx={{ fontSize: 40 }} />,
    action: 11
  }
]

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});