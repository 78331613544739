import { useContext, useEffect, useState } from "react";
import { Button, Divider, Fab, Grid, IconButton, InputBase, List, Paper, Tooltip } from "@mui/material";
import { Close as CloseIcon, Search as SearchIcon, Block as BlockIcon, Download as DownloadIcon } from '@mui/icons-material'
import RSDlist from "components/List/RSDList";
import { useQuery, useQueryClient } from "react-query"
import { getBasicListRealStateDevelopments } from "services/api/RealStateDevelopment"
import { ValidateNullUndefinedEmpty } from "services/helpers/DataValidations";
import { SnackbarGlobal } from "components/Snackbar";
import LoaderRSDOptions from "components/Loaders/RSDAdmin";
import { getProductsByRealStateDevelopmentSadasiUsers } from "services/api/Product";
import Price from "./Price";
import MinimumRecoveryValue from "./MinimumRecoveryValue";
import Download from "./Download";
import { AuthContext } from "context/AuthContext";

export default function UpdateProducts() {
  const { setTitlePage } = useContext(AuthContext);
  const [dataFilter, setDataFilter] = useState(null)
  const [idSelected, setIdSelected] = useState(null)
  const [rsdSelected, setRsdSelected] = useState(null)
  const [instanceSelected, setInstanceSelected] = useState({})
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: update price, 2: download
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const queryClient = useQueryClient()
  const { error, data } = useQuery(
    'realStateDevelopmentBasicList', getBasicListRealStateDevelopments, { refetchOnWindowFocus: false }
  )
  const { isLoading: iLrsdOne, isFetching: iFrsdOne, data: rsdOne } = useQuery(
    ['productsList', idSelected],
    () => getProductsByRealStateDevelopmentSadasiUsers(idSelected),
    { refetchOnWindowFocus: false, enabled: (idSelected !== null && idSelected !== undefined) }
  )

  useEffect(() => {
    setTitlePage('Actualizar precios');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => { if (data !== undefined && data !== null && data !== "") setDataFilter(data.data) }, [data])

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const handlerSelectedRSD = (rsd) => { setIdSelected(rsd._id); setRsdSelected(rsd) }
  const handleRefreshData = () => { queryClient.invalidateQueries('productsList') }
  const handleResetActions = () => { setOpenAction(0) }

  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    if (success) { handleResetActions(); handleRefreshData() }
  }

  const handleDatePriceUpdated = (date) => {
    const date_f = new Date(date)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date_f.toLocaleDateString('es-ES', options)
  }

  if (error) return <div>Ha ocurrido un error</div>

  return (<>
    <div className="rsd-container">
      <div className="rsd-left-siderbar-container">
        <Paper sx={{ p: '2px 4px', mb: 1, display: 'flex', alignItems: 'center', width: '100%' }} >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Buscar" onChange={handleSearch} inputProps={{ 'aria-label': 'search' }} />
          <SearchIcon style={{ margin: 10 }} />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton onClick={() => setOpenAction(2)}><DownloadIcon style={{ margin: 10 }} /></IconButton>
        </Paper>
        <div className="rsd-left-siderbar-main">
          {(dataFilter !== undefined && dataFilter !== null) ? <>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {dataFilter.map((rsd, index) => (
                <RSDlist key={`${index}-${rsd._id}`} {...rsd} onClick={() => handlerSelectedRSD(rsd)} isFocus={rsd._id === idSelected} />
              ))}
            </List>
          </> : null}
        </div>
      </div>
      <div className="rsd-main">
        {idSelected === null ? <p>Selecciona un desarrollo para poder acceder a sus opciones</p> : null}
        {iLrsdOne && iFrsdOne ? <LoaderRSDOptions /> :
          <div>
            {ValidateNullUndefinedEmpty(rsdOne) && rsdOne.hasOwnProperty('data') ? <>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", position: "sticky", top: 64, backgroundColor: "white", zIndex: 2, paddingBottom: 30 }} >
                <img src={rsdSelected.logo} style={{ height: 60 }} alt="logotipo-desarrollo" />
                <span className="fab_close">
                  <Tooltip title="Cerrar"><Fab color="primary" size="small" onClick={() => setIdSelected(null)}><CloseIcon /></Fab></Tooltip>
                </span>
              </div>
              <Grid container spacing={2}>
                {rsdOne.data.map((item, index) => (
                  <Grid key={`${index}-${item.name}`} item xs={12}>
                    <Paper style={{ display: "flex" }}>
                      <img src={item.facade} style={{ width: "100%", maxWidth: 150, objectFit: "cover" }} alt={`fachada-producto-${item.name}`} />
                      <div style={{ fontSize: 18, padding: 8 }}>
                        <div style={{ display: "flex", fontWeight: "bold", color: item.active ? "#000000" : "#a7a4a4" }}>
                          <span style={{ marginRight: 8 }}>{item.name}</span>
                          {!item.active ? <BlockIcon /> : null}
                        </div>
                        <div style={{ display: "flex", fontWeight: "bold", color: item.active ? "#000000" : "#a7a4a4" }}>
                          <span>Avalúo: ${item.price.toLocaleString("es-MX")}</span>
                        </div>
                        <div style={{ fontSize: 14 }}>
                          Actualizado por: { item.priceLogLatest.user !== undefined ? item.priceLogLatest.user.name : "" }
                          <br/>
                          Día: { item.priceLogLatest.dateTime !== undefined ? handleDatePriceUpdated(item.priceLogLatest.dateTime) : "" }
                        </div>
                        <div style={{ display: "flex", fontWeight: "bold", color: item.active ? "#000000" : "#a7a4a4" }}>
                          <span>Valor mínimo de recuperación: ${item.minimum_recovery_value.toLocaleString("es-MX")}</span>
                        </div>
                        <div style={{ fontSize: 15, color: item.active ? "#000000" : "#a7a4a4" }}>
                          <span>Proximamente: {item.is_coming_soon ? "Sí" : "No"}</span><br />
                          <span>Disponible: {item.is_available ? "Sí" : "No"}</span>
                        </div>
                        {item.active ?
                          <div>
                            <p>
                              <Button variant="contained" onClick={() => { setInstanceSelected(item); setOpenAction(1) }}>Actualizar precio</Button>
                            </p>
                            <Button variant="contained" onClick={() => { setInstanceSelected(item); setOpenAction(3) }}>Actualizar valor mínimo de recuperación</Button>
                          </div>
                          : null}
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </> : null}
          </div>}
      </div>
    </div>
    {openAction === 1 ?
      <Price open={openAction === 1} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} /> : null}
    <Download open={openAction === 2} close={() => handleResetActions()} />
    {openAction === 3 ?
      <MinimumRecoveryValue open={openAction === 3} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} /> : null}
    <SnackbarGlobal vertical='bottom' horizontal='center' open={notification.open} close={() => setNotification({ open: false, message: "", severity: "" })} message={notification.message} severity={notification.severity} />
  </>)
}