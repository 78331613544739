import { yupResolver } from "@hookform/resolvers/yup";
import { Button, IconButton, InputLabel, TextField } from "@mui/material";
import { Image as ImageIcon} from "@mui/icons-material"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { ValidationSchemaFeature } from "domain/Product/services/SchemaValidation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ImageInput from "components/Image/ImageInput";
//import DialogImageManager from "domain/ImageManager/DialogImageManager";
import MediaModal from "domain/Media/MediaModal";

export default function Add({ open, close, rsdId, action }) {
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaFeature) 
  })

  const handleClose = () => {
    setDataInstance({})
    reset()
    close()
  }

  const handleAdd = (params) => {
    //console.log(params)
    action(params)
    handleClose()
  }

  const handleChangeData = (event) => { setDataInstance({...dataInstance, [event.target.name]: event.target.value}) }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const Actions = () => (<>
    <Button onClick={handleClose}>Cancelar</Button>
    <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
  </> )

  return (<>
    <FlexibleWidthDialog open={open} title="Agregar plano" actions={<Actions />}>
    <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
      <ImageInput image={ dataInstance.icon }/>
      <div>
        <InputLabel htmlFor='icon' required style={{ fontSize: 14 }}>Icono</InputLabel>
        <TextField id='icon' fullWidth margin="dense" type="text" name='icon'
          value={dataInstance.icon || ""}
          {...register(`icon`)} 
          error={errors.icon ? true: false}
          onChange={handleChangeData} />
        <ValidateErrorMessage message={errors.icon?.message} />
        <IconButton onClick={ () => { handleFieldImageManager('icon'); handleOpenImageManager(); } }><ImageIcon /></IconButton>
      </div>
    </div>
    <br />
    <InputLabel htmlFor='feature' required style={{ fontSize: 14 }}>Nombre</InputLabel>
    <TextField id='feature' fullWidth margin="dense" type="text" name='feature'
      value={dataInstance.feature || ""}
      {...register(`feature`)} 
      error={errors.feature ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errors.feature?.message} />
    <br />
    <InputLabel htmlFor='quantity' required style={{ fontSize: 14 }}>Cantidad</InputLabel>
    <TextField id='quantity' fullWidth margin="dense" type="number" name='quantity'
      value={dataInstance.quantity || ""}
      {...register(`quantity`)} 
      error={errors.quantity ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errors.quantity?.message} />
    </FlexibleWidthDialog>
    {/* <DialogImageManager open={ imageManagerModalOpen } 
      actionClose={ handleCloseImageManager } 
      setImageSelected={ handleSelectImageManager } /> */}
    <MediaModal open={imageManagerModalOpen} setOpen={handleCloseImageManager} mediaSelected={handleSelectImageManager} path={`products/features`} />
  </>)
}