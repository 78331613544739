import { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton, InputLabel, Switch, TextField } from "@mui/material";
import { Image as ImageIcon } from "@mui/icons-material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'
import { modulesRQ } from "services/helpers/ReactQuillOptions";
import { ValidationSchemaHeroes } from "../services/SchemaValidation"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { editRealStateDevelopmentHeroes } from "services/api/RealStateDevelopment";
import MediaModal from "domain/Media/MediaModal";
import ReactQuill from "react-quill";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { schemaAlttaHomes } from './Schema'

export default function Heroes({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit: hsEdit, register, formState: { errors }, setValue/* , getValues */ } = useForm({
    resolver: yupResolver(ValidationSchemaHeroes)
  })

  useEffect(() => {
    if (open) {
      setDataInstance(instance.infoHeroes !== undefined ? instance.infoHeroes : schemaAlttaHomes)
      reset(instance.infoHeroes !== undefined ? instance.infoHeroes : schemaAlttaHomes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(editRealStateDevelopmentHeroes, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleEdit = (params) => {
    /* console.log("prueba", params)*/
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: params })
  }

  const handleClose = () => { reset(); /*setDataInstance({});*/ close() }

  const handleOnChangeData = (event) => {
    ////console.log("handleChangeDataSelect", event.target.value)
    if (event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeDataBoolean = (data) => {
    const newValue = !dataInstance[data]
    setDataInstance({ ...dataInstance, [data]: newValue })
    setValue(data, newValue)
  }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }
  const handleCloseImageManager = (prop) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = (prop) => { setDataFieldImageManager(prop) }

  const handleSelectImageManager = (url) => {
    setDataInstance({ ...dataInstance, [dataFieldImageManager]: url })
    setValue(dataFieldImageManager, url)
  }

  const handleChangeRichEditor = (field, value) => { //console.log("RichEditor", `${field} - ${value}`)
    if (field.includes(".")) {
      const separate = field.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: value } })
      setValue(field, value)
      return;
    }
    setDataInstance({ ...dataInstance, [field]: value })
    setValue(field, value)
  }

  const handleCopyFromHome = (field) => {
    switch (field) {
      case "description":
        setDataInstance({ ...dataInstance, [field]: instance.description })
        setValue(field, instance.description)
        break;
      case "amenities_description":
        setDataInstance({ ...dataInstance, [field]: instance.amenities.description })
        setValue(field, instance.amenities.description)
        break;
      case "credits_description":
        setDataInstance({ ...dataInstance, [field]: instance.credits.description })
        setValue(field, instance.credits.description)
        break;
      default:
        break;
    }
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={hsEdit(handleEdit)}>Editar</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Editar info Héroes - ${instance.name}`} actions={<Actions />}>
      <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
      <div> <ReactQuill value={dataInstance.description || ""} modules={modulesRQ} onChange={(value) => { handleChangeRichEditor('description', value) }} /> </div>
      <ValidateErrorMessage message={errors.description?.message} />
      <Button onClick={() => handleCopyFromHome("description")}>Copiar de home</Button>
      <br />
      <InputLabel htmlFor='show_in_random_image_home' required style={{ fontSize: 14 }}>Mostrar en imagenes aleatorias en home?</InputLabel>
      <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-show_in_random_image_home` }}
        onClick={() => handleChangeDataBoolean('show_in_random_image_home')}
        checked={dataInstance.show_in_random_image_home || false}
        {...register(`show_in_random_image_home`)}
      />
      <br />
      <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
        <div style={{ textAlign: "center" }}>
          <img src={dataInstance.random_image_home || ""} style={{ width: "90%" }} alt="for slide home" />
        </div>
        <div>
          <InputLabel htmlFor='random_image_home' required={dataInstance.show_in_random_image_home} style={{ fontSize: 14 }}>Imagen aleatoria en home</InputLabel>
          <TextField id='random_image_home' fullWidth margin="dense" type="text" name='random_image_home'
            value={dataInstance.random_image_home || ""}
            {...register(`random_image_home`)} error={errors.random_image_home ? true : false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.random_image_home?.message} />
          <IconButton onClick={() => { handleFieldImageManager('random_image_home'); handleOpenImageManager(); }}><ImageIcon /></IconButton>
        </div>
      </div>
      <br />
      <InputLabel htmlFor='show_in_featured_image_home' required style={{ fontSize: 14 }}>Mostrar en imagenes destacadas en home?</InputLabel>
      <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-show_in_featured_image_home` }}
        onClick={() => handleChangeDataBoolean('show_in_featured_image_home')}
        checked={dataInstance.show_in_featured_image_home || false}
        {...register(`show_in_featured_image_home`)}
      />
      <br />
      <InputLabel htmlFor='amenities_description' required style={{ fontSize: 14 }}>Descripción de Amenidades</InputLabel>
      <div> <ReactQuill value={dataInstance.amenities_description || ""} onChange={(value) => { handleChangeRichEditor('amenities_description', value) }} modules={modulesRQ} /> </div>
      <ValidateErrorMessage message={errors.amenities_description?.message} />
      <Button onClick={() => handleCopyFromHome("amenities_description")}>Copiar de home</Button>
      <br />
      <InputLabel htmlFor='credits_description' required style={{ fontSize: 14 }}>Descripción de créditos</InputLabel>
      <div> <ReactQuill value={dataInstance.credits_description || ""} modules={modulesRQ} onChange={(value) => { handleChangeRichEditor('credits_description', value) }} /> </div>
      <ValidateErrorMessage message={errors.credits_description?.message} />
      <Button onClick={() => handleCopyFromHome("credits_description")}>Copiar de home</Button>
    </FlexibleWidthDialog>
    <MediaModal open={imageManagerModalOpen} setOpen={handleCloseImageManager} mediaSelected={handleSelectImageManager} path={`real-state-developments/${instance._id}`} />
  </>)
}