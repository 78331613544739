import { useEffect, useState } from "react";
import { Button, CircularProgress, Divider, Grid, IconButton, InputLabel, TextField } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'
import { ValidationSchemaArraySalesupTokens } from "../services/SchemaValidation"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { editRealStateDevelopmentSalesupTokens } from "services/api/RealStateDevelopment";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";

export default function SalesupTokens({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState([])
  let { reset, handleSubmit, register, formState: { errors }, control, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaArraySalesupTokens), 
    defaultValues: { 'salesup_tokens': [] } 
  })
  const {fields: fTBP, append: aTBP, update: updTBP} = useFieldArray({ 
    name: 'salesup_tokens', control: control
  })

  useEffect(() => {
    if(open) {
      setDataInstance(instance.salesup_tokens)
      setValue("salesup_tokens", instance.salesup_tokens)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(editRealStateDevelopmentSalesupTokens, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleEdit = (params) => { 
    console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: params })
  }
  
  const handleClose = () => { reset(); setDataInstance([]); close() }

  const handleChangeData = (index, event) => {
    const name = event.target.name.split(".")
    const newState = dataInstance.map((item, i) => {
      if (i === index) { return {...item, [name[2]]: event.target.value} }
      return item
    });
    setDataInstance(newState)

    let data = fTBP[index]
    data[name[2]] = event.target.value;
    updTBP(index, data)
  }

  const handleAddSUToken = () => {
    let newToken = { name: "", value: "" }
    aTBP(newToken)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} actions={<Actions />}
      title={ <Grid container spacing={2}>
        <Grid item xs={10}> SalesUp tokens - {instance.name} </Grid>
        <Grid item xs={2}> <IconButton onClick={() => handleAddSUToken()}><AddIcon /></IconButton> </Grid>
      </Grid> } 
    >
      {fTBP.map((input, index) => {
        return (
          <div key={index}>
            <InputLabel htmlFor={`name-${index}`} required style={{ fontSize: 14 }}>Nombre</InputLabel>
            <TextField id={`name-${index}`} fullWidth margin="dense" type="text" size="small"
              value={dataInstance[index].name || ""}
              {...register(`salesup_tokens.${index}.name`)} 
              error={errors?.salesup_tokens !== undefined ? errors?.salesup_tokens[index]?.name ? true: false : false}
              onChange={(event) => handleChangeData(index, event)} />
            <ValidateErrorMessage message={errors?.salesup_tokens !== undefined ? errors?.salesup_tokens[index]?.name?.message : ""} />
            <br />
            <InputLabel htmlFor={`value-${index}`} required style={{ fontSize: 14 }}>Valor</InputLabel>
            <TextField id={`value-${index}`} fullWidth margin="dense" type="text" size="small"
              value={dataInstance[index].value || ""}
              {...register(`salesup_tokens.${index}.value`)} 
              error={errors?.salesup_tokens !== undefined ? errors?.salesup_tokens[index]?.value ? true: false : false}
              onChange={(event) => handleChangeData(index, event)} />
            <ValidateErrorMessage message={errors?.salesup_tokens !== undefined ? errors?.salesup_tokens[index]?.value?.message: ""} />
            {index !== (fTBP.length - 1) ? <Divider sx={{ marginTop: 2, marginBottom: 2, borderColor: "#e76627" }} /> : null }
          </div>
        )
      })}
    </FlexibleWidthDialog>
  </>)
}