import { Button, IconButton, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import { Image as ImageIcon } from "@mui/icons-material";

import ImageInput from "components/Image/ImageInput";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";

export default function ImageForm({ mode, images, dataInstance, changeDataInstance, formAttributes, imageManager, actions}) {
  
  const handleChangeData = (field, value) => { changeDataInstance("image", field, value) }

  return ( <>
    <Typography variant="h6" component="h6"> { mode ? "Agregar imagen" : "Editar imagen" } </Typography><br />
    <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
      <ImageInput image={ dataInstance.image }/>
      <div>
        <InputLabel htmlFor='image' required style={{ fontSize: 14 }}>Imagen</InputLabel>
        <TextField id='image' fullWidth margin="dense" type="text" name='image'
          value={dataInstance.image || ""}
          {...formAttributes.register(`image`)}
          error={formAttributes.errors.image ? true: false}
          onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
        <ValidateErrorMessage message={formAttributes.errors.image?.message} />
        <IconButton onClick={ () => { imageManager.setField('image'); imageManager.open(); } }><ImageIcon /></IconButton>
      </div>
    </div>
    <br />
    <InputLabel htmlFor='view' required style={{ fontSize: 14 }}>Vista</InputLabel>
    <TextField id="view" fullWidth margin="dense" name='view' select
      value={dataInstance.view || []}
      {...formAttributes.register(`view`)} error={formAttributes.errors.view ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } >
        <MenuItem value="pc" disabled={images.some(img => img.view === "pc")}> PC </MenuItem>
        <MenuItem value="mobile" disabled={images.some(img => img.view === "mobile")}> Móvil </MenuItem>
    </TextField>
    <ValidateErrorMessage message={formAttributes.errors.view?.message} />
    <br />
    <div style={{ textAlign: "right" }}>
      <Button onClick={actions.cancelAction} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
      { (!mode) ? <Button onClick={actions.editAction} variant="contained" color="secondary"> Actualizar </Button>
      : <Button onClick={formAttributes.submitValidation(actions.saveAction)} variant="contained" color="primary"> Agregar </Button> }
    </div>
  </> )
}