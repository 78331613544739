import { Block as BlockIcon } from '@mui/icons-material'
import { Avatar } from '@mui/material'

export default function TypeOne({ name, subname = null, logo, image, active = true, actions }) {  
  return (
    <div className="card-typeone-paper" style={{ backgroundColor: active ? "white" : "#cbcbcb" }}>
      <div className="card-typeone-header">
        <div className="card-typeone-image_decorator" style={{ backgroundColor: active ? "currentcolor" : "#cbcbcb" }}></div>
        <div className="card-typeone-image_container">          
          { active ? <img className="card-typeone-image" alt="icon" src={logo}/>
            : <Avatar><BlockIcon fontSize="small"/></Avatar> }
        </div>
        <div className="card-typeone-image_backgroud_filter"></div>
        <span className="card-typeone-image_backgroud_container">
          <span className="card-typeone-image_backgroud_wrapper">
            <img className="card-typeone-image_backgroud" alt="prueba" src={image}/>
          </span>
        </span>
      </div>
      <div className="card-typeone-info_container">
        <div className={"card-typeone-name"}> 
          { name }
          { (subname !== null) && 
            <div style={{ fontSize: 12 }}>
              { subname }
            </div> 
          }  
        </div>
        <hr className="card-typeone-divider" />
        <div className="card-typeone-actions"> {actions} </div>
      </div>
    </div>
  )
}