import * as Yup from 'yup'

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Correo Electrónico es requerido')
    .email('Correo Electrónico no es válido'),
  password: Yup.string()
    .required('Contraseña es requerida')
})

export default ValidationSchema