import { yupResolver } from "@hookform/resolvers/yup";
import { Button, IconButton, InputLabel, TextField } from "@mui/material";
import { Image as ImageIcon} from "@mui/icons-material"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { ValidationSchemaAmenity } from "domain/RealStateDevelopment/services/SchemaValidation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ImageInput from "components/Image/ImageInput";
import MediaModal from "domain/Media/MediaModal";

export default function Edit({ open, close, instance, rsdId, action }) {
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaAmenity) 
  })

  useEffect(() => {
    //console.log(instance)
    setDataInstance(instance)
    setValue("icon", instance.icon)
    setValue("name", instance.name)
  }, [open])

  const handleClose = () => {
    setDataInstance({})
    reset()
    close()
  }

  const handleEdit = (params) => {
    //console.log(params)
    action(params)
    handleClose()
  }

  const handleChangeData = (event) => { setDataInstance({...dataInstance, [event.target.name]: event.target.value}) }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const Actions = () => (<>
    <Button onClick={handleClose}>Cancelar</Button>
    <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
  </> )

  return (<>
    <FlexibleWidthDialog open={open} title="Editar imagen" actions={<Actions />}>
      <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
        <ImageInput image={ dataInstance.icon }/>
        <div>
          <InputLabel htmlFor='icon' required style={{ fontSize: 14 }}>Icono</InputLabel>
          <TextField id='icon' fullWidth margin="dense" type="text" name='icon'
            value={dataInstance.icon || ""}
            {...register(`icon`)} 
            error={errors.icon ? true: false}
            onChange={handleChangeData} />
          <ValidateErrorMessage message={errors.icon?.message} />
          <IconButton onClick={ () => { handleFieldImageManager('icon'); handleOpenImageManager(); } }><ImageIcon /></IconButton>
        </div>
      </div>
      <br />
      <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
      <TextField id='name' fullWidth margin="dense" type="text" name='name'
        value={dataInstance.name || ""}
        {...register(`name`)} 
        error={errors.name ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errors.name?.message} />
    </FlexibleWidthDialog>
    <MediaModal open={imageManagerModalOpen} setOpen={handleCloseImageManager} mediaSelected={handleSelectImageManager} path={`real-state-developments/amenities`} />
  </>)
}