import { API_BASE_URL/* , HEADERS_WITH_TOKEN */ } from "./BaseUrl";

// 
export const getWhatsAppTrackingByRealStateDevelopment = async (params) => {
  const { id, month, year } = params
  let url_params = `month=${month}&year=${year}`
  if (id !== null) { url_params += `&rsd=${id}` }
  const res = await fetch(
    `${API_BASE_URL}/web/whatsapp-tracking-report?${url_params}`,
    {
      method: 'GET',
      //headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  return await res.json()
}