import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, IconButton,InputLabel, Typography } from "@mui/material";
import { Save as SaveIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon, Add as AddIcon } from "@mui/icons-material";
import { useMutation } from "react-query";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ValidationSchemaAmenities } from "../services/SchemaValidation";
import FullScreenDialog from "components/Dialog/FullScreenDialog";
import ImageGalleryCard from "components/Card/ImageLeftCard";
import { editRealStateDevelopmentAmenities } from "services/api/RealStateDevelopment";
import Add from "./Add";
import Edit from "./Edit";
import Remove from "./Remove";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { modulesRQ } from "services/helpers/ReactQuillOptions";

export default function ImagesGallery({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  const [position, setPosition] = useState(null)
  const [dataInstanceAmenities, setDataInstanceAmenities] = useState({})
  const [instanceSelected, setInstanceSelected] = useState({})
    let { reset: rTBP, handleSubmit: hsTBP, register: regTBP, formState: { errors: eTBP }, control: cTBP, setValue: svTBP, getValues } = useForm({ 
    resolver: yupResolver(ValidationSchemaAmenities), 
    defaultValues: { 'description': "", 'amenities_list': [] } 
  })
  const {fields: fTBP, append: aTBP, remove: rmvTBP, update: updTBP, move: mvTBP} = useFieldArray({ 
    name: 'amenities_list', control: cTBP 
  })
    
  useEffect(() => { 
    if (open) { 
      svTBP('description', instance.amenities.description) 
      svTBP('amenities_list', instance.amenities.amenities_list)
      setDataInstanceAmenities(instance.amenities)
    } 
  }, [open])

  const mutation = useMutation(editRealStateDevelopmentAmenities, {
    onSuccess: (data) => {
      setIsLoading(false)
      /* if(data.status === "error") { 
        reportAction('amenities', false, { message: 'Error al editar los datos'}, false)
      } else { 
        reportAction('amenities', false, { }, true) 
      } */
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const onSubmit = (params) => { //console.log("onSubmit", params)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: params })
  }

  const handleClose = () => { setDataInstanceAmenities({}); rTBP(); close(); }

  const handleAddA = (data) => { aTBP(data) }
  const handleLoadEditA = (position, data) => {
    setPosition(position)
    setInstanceSelected(data)
  }
  const handleEditA = (data) => { 
    let dataTemp = { ...fTBP[position], icon: data.icon, name: data.name }
    updTBP(position, dataTemp) 
  }
  const handleLoadRemoveA = (position, data) => {
    setPosition(position)
    setInstanceSelected(data)
  }
  const handleRemoveA = () => { rmvTBP(position) }
  const handlerChangePositionA = (direction, position) => { mvTBP(position, direction === 'up' ? position - 1 : position + 1) }

  const handleChangeRichEditor = (value) => { //console.log("RichEditor", value)
    setDataInstanceAmenities({...dataInstanceAmenities, description: value})
    svTBP('description', value)
  }

  const Actions = ({ item, index, totalItems }) => ( <>
    <IconButton aria-label="edit" onClick={ () => { handleLoadEditA(index, item); setOpenAction(2) }  }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => { handleLoadRemoveA(index, item); setOpenAction(3) } }> <DeleteIcon color='error'/> </IconButton>
    <div>
    {index !== 0 && <IconButton aria-label="up-position" onClick={ () => handlerChangePositionA("up", index) }> <ArrowUpwardIcon /> </IconButton>}
    {index !== totalItems - 1 && <IconButton aria-label="down-position" onClick={ () => handlerChangePositionA("down", index) }> <ArrowDownwardIcon /> </IconButton> }
    </div>
  </> )

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = getValues("amenities_list");
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    svTBP('amenities_list', updatedList)
  }  

  return ( <>
    <FullScreenDialog open={ open } changeOpen={ handleClose } title={`Amenidades - ${instance.name}`} actions={ <>
      { !isLoading ? <>
        <IconButton autoFocus color="inherit" onClick={ () => setOpenAction(1) } aria-label="add"> <AddIcon /> </IconButton> 
        <IconButton autoFocus color="inherit" onClick={ hsTBP(onSubmit) } aria-label="save"> <SaveIcon /> </IconButton>
      </> : <CircularProgress /> }
    </> }>
      <div className="admin-image-gallery one-columns">
        <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
        <div> <ReactQuill value={dataInstanceAmenities.description || ""} onChange={handleChangeRichEditor} modules={modulesRQ}/> </div>
        <ValidateErrorMessage message={eTBP.description?.message} />
        <div className="list-container">
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="droppable">
              { (provided) => (
                <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                { (fTBP.length > 0) ? (
                fTBP.map((item, i) => (
                  <Draggable key={item.id} draggableId={item.id} index={i}>
                    {(provided) => (
                      <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                        <ImageGalleryCard 
                          image={item.icon} 
                          description={item.name} 
                          actions={ 
                            <Actions item={ item } index={ i } totalItems={ fTBP.length }/> 
                          }
                        />
                      </Grid>
                    )}
                  </Draggable>
                )) ) : <Grid item xs={12}> <Typography variant="inherit">No hay amenidades agregadas</Typography> </Grid> }
                </Grid>
              ) }
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </FullScreenDialog>
    <Add open={ openAction === 1 } close={ () => setOpenAction(0) } rsdId={ instance._id } action={ handleAddA } />
    <Edit open={ openAction === 2 } close={ () => setOpenAction(0) } rsdId={ instance._id } instance={ instanceSelected } action={ handleEditA } />
    <Remove open={ openAction === 3 } close={ () => setOpenAction(0) } instance={ instanceSelected } action={ handleRemoveA } />
  </> )
}