import { Button, CircularProgress, IconButton } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { storage } from "../../../firebase/firebase"
import { ref, getDownloadURL, uploadBytesResumable, list, deleteObject } from "firebase/storage";
import styles from './styles.module.css'
import { ContentPasteSearchOutlined, Delete as DeleteIcon } from '@mui/icons-material'
import ToolBar from "../../../components/ToolBar";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";

export default function GenerateAutomaticGallery({ open, setOpen, setImages, path }) {
  const [isLoading, setIsLoading] = useState(false)
  const [imagesListFS, setImagesListFS] = useState([])
  const [imagesSelected, setImagesSelected] = useState([])
  const [dataFilter, setDataFilter] = useState(null);
  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [imageDelete, setImageDelete] = useState("");

  const handleClose = () => { 
    setIsLoading(false);
    setImagesListFS([]); 
    setImagesSelected([]);
    setOpen(false) 
  }

  useEffect(() => { if(open) handleGetImagesListFromPath() }, [open])

  const handleGetImagesListFromPath = async() => {
    setImagesListFS([])
    setIsLoading(true)
    const listRef = ref(storage, path);
    list(listRef, { maxResults: 100 }).then((resp) => {
      let promises = resp.items.map((imageRef) => getDownloadURL(imageRef));
      Promise.all(promises).then((urls) => { 
        setImagesListFS(urls)
        setDataFilter(urls)
        setIsLoading(false)
      })
    }).catch((error) => { console.log("Error", error)  });
  }
  
  const handleGetNameImage = (img) => {
    const sep1 = img.split("?")
    const sep2 = sep1[0].split("%2F")
    return sep2[sep2.length - 1]
  }

  const handleSelectImage = (img) => { 
    if(imagesSelected.includes(img)) {
      setImagesSelected(imagesSelected.filter(image => image !== img))
    } else {
      setImagesSelected([...imagesSelected, img])
    }
  }

  const handlerSelectAllImages = () => { 
    if(dataFilter !== null) {
      setImagesSelected(dataFilter)  
    } else {
      setImagesSelected(imagesListFS)
    }
  }

  const handleSearch = (e) => {
    const dataFilter = imagesListFS.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const handleFormHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files;
    for (let index = 0; index < file.length; index++) {
      handleUploadFiles(file[index]);
    }
    //const file = e.target[0].files[0];
    //handleUploadFiles(file);
  };

  const handleUploadFiles = (file) => {
    if (!file) return;
    const sotrageRef = ref(storage, `${path}/${file.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageUrl(downloadURL)
          handleGetImagesListFromPath()
          handleClearImageUrl()
        });
      }
    );
  };

  const handleClearImageUrl = () => { setTimeout(() => { setImageUrl(""); setProgress(0) }, 2000) }
  const handleSelectImageToDelete = (img) => { setImageDelete(img); }
  const handleDeleteImage = () => {
    const deserRef = ref(storage, imageDelete)
    deleteObject(deserRef).then(() => {
      alert("Archivo eliminado")
      handleGetImagesListFromPath()
      setImageDelete("")
    }).catch((error) => {
      alert("El archivo no se a podido eliminar")
      setImageDelete("")
    })
  }

  const Actions = () => (<>
    { !isLoading ? <> 
      <Button onClick={handleClose}>Cancel</Button> 
      { imagesSelected.length > 0 &&
      <Button variant="contained" color="primary" onClick={() => {setImages(imagesSelected); handleClose();} }>Seleccionar</Button> }
    </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title="Generar galeria rápida" maxWidth="lg" actions={<Actions />}>
      <div className={ styles.toolbar_container }>
        <ToolBar searchFunction={handleSearch} />
      </div>
      <div className={styles.container_main}>
        <div className={styles.media_container_image_cards}>
        { !isLoading ? (
          (dataFilter !== null) ? 
            dataFilter.map((img, index) => 
            <div key={index} className={ imagesSelected.includes(img) ? styles.media_image_card_selected : styles.media_image_card}>
              <img src={img} className={styles.media_image_card_image} onClick={() => handleSelectImage(img)}/> <br />
              {handleGetNameImage(img)} <br />
              { imageDelete === "" && <IconButton onClick={ () => handleSelectImageToDelete(img) }> <DeleteIcon color="error" /> </IconButton> }
            </div> ) 
          : <div>No hay archivos</div>
          ) : <CircularProgress />
        }
        </div>
        <div>
          { imageDelete === "" && <>
          <form onSubmit={handleFormHandler} className={styles.uploadForm}>
            <input type="file" className="input" multiple/> <br /><br />
            <Button type="submit" variant="contained" color="primary">Subir Archivo</Button>
            <hr />
          </form>
          { progress !== 0 && <h5>Progreso: {progress}%</h5> }
          { imageUrl !== "" && <img src={imageUrl} height="100"/> }
          </> }
          { imageDelete !== "" &&
            <div className={styles.deleteMessage}>
              <img src={imageDelete} height="110"/><br />
              <div style={{ marginBottom: 16}}>¿Esta seguro de querer eliminar esta imagen ?</div>
              <div>
                <Button onClick={() => setImageDelete("")} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
                <Button onClick={() => handleDeleteImage()} variant="contained" color="error"> Eliminar </Button>
              </div>
            </div>
          }
          <Button onClick={handlerSelectAllImages}>Seleccionar Todos</Button>
        </div>
      </div>
    </FlexibleWidthDialog>
  </>)
}

//udemy fernando herrera