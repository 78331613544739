import { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AuthContext } from "context/AuthContext";

export default function Dashboard() {
  const { setTitlePage } = useContext(AuthContext);

  useEffect(() => {
    setTitlePage('Dashboard')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<>
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "80vh" }} >
      <Typography variant="h2" style={{ textAlign: "center" }}>Bienvenid@</Typography>
      <Typography variant="h3" style={{ textAlign: "center" }}>CRM de Productos Sadasi</Typography>
    </Box>
  </>)
}