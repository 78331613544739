import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputLabel, TextField } from "@mui/material";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { ValidationSchemaFloor } from "domain/Product/services/SchemaValidation";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function Edit({ open, close, instance, action }) {
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaFloor) 
  })

  useEffect(() => {
    //console.log(instance)
    setDataInstance(instance)
    setValue("name", instance.name)
  }, [open])

  const handleClose = () => {
    setDataInstance({})
    reset()
    close()
  }

  const handleEdit = (params) => {
    //console.log(params)
    action(params)
    handleClose()
  }

  const handleChangeData = (event) => { setDataInstance({...dataInstance, [event.target.name]: event.target.value}) }

  const Actions = () => (<>
    <Button onClick={handleClose}>Cancelar</Button>
    <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
  </> )

  return (
    <FlexibleWidthDialog open={open} title="Editar piso" actions={<Actions />}>
      <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
      <TextField id='name' fullWidth margin="dense" type="text" name='name'
        value={dataInstance.name || ""}
        {...register(`name`)} 
        error={errors.name ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errors.name?.message} />      
    </FlexibleWidthDialog>
  )
}