import { useEffect, useState } from "react";
import { Autocomplete, Button, CircularProgress, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Switch, TextField } from "@mui/material";
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'

import { ValidationSchemaUpdate } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { editUser } from "services/api/User";

export default function Edit({ open, close, instance, reportAction, roles, rsd }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchemaUpdate)
  })

  useEffect(() => {
    if (open ) {
      setDataInstance(instance)
      setValue('name', instance.name)
      setValue('role', instance.role)
      setValue('permitions', instance.permitions)
      setValue('developments', instance.developments)
      setValue('active', instance.active)
    }
  }, [open])

  const mutation = useMutation(editUser, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleAdd = (params) => { 
    //console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: params })
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { 
    setDataInstance({...dataInstance, [event.target.name]: event.target.value}) 
  }

  const handleChangeActive = () => {
    setDataInstance({...dataInstance, active: !dataInstance.active}) 
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  const handleChangeDataSelect = (prop, value) => {
    setDataInstance({...dataInstance, [prop]: value})
    setValue(prop, value)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Editar usuario`} actions={<Actions />}>
      <>
      <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataInstance.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <InputLabel htmlFor='password' required style={{ fontSize: 14 }}>Contraseña</InputLabel>
        <OutlinedInput id="password" fullWidth margin="dense" type={showPassword ? 'text' : 'password'} name='password'
          value={dataInstance.password || ""}
          {...register(`password`)} 
          error={errors.password ? true: false}
          onChange={handleOnChangeData}
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" aria-label="toggle password visibility"
                onClick={ handleClickShowPassword }
                onMouseDown={ (event) => event.preventDefault() }
              > { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> } </IconButton>
            </InputAdornment>
          }
        />
        <ValidateErrorMessage message={errors.password?.message} />
        <br />
        <InputLabel htmlFor='role' required style={{ fontSize: 14 }}>Rol</InputLabel>
        <Select id="role" fullWidth margin="dense" name='role'
          value={dataInstance.role || ""}
          {...register(`role`)} 
          error={errors.role ? true: false}
          onChange={handleOnChangeData}
        >
          { roles.map((rol) => 
            <MenuItem key={rol._id} value={rol._id}>{rol.name}</MenuItem>
          ) }
        </Select>
        <ValidateErrorMessage message={errors.role?.message} />
        <br />
        <InputLabel htmlFor='developments' style={{ fontSize: 14 }}>Desarrollos</InputLabel>
        <Autocomplete multiple id="developments"
          options={ rsd }
          getOptionLabel={ (option) => `${option.name} (${option.city.name})` }
          defaultValue={ dataInstance.developments ? rsd.filter(value => dataInstance.developments.includes(value._id)) : [] }
          onChange={ (event, newValue) => { handleChangeDataSelect("developments", newValue.map(value => value._id)) } }
          renderInput={(params) => ( <TextField {...params} variant="outlined" placeholder="Desarrollos"/> )} />
        <ValidateErrorMessage message={errors.developments?.message} />
        <br />
        <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Activo?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={handleChangeActive}
          checked={dataInstance.active || false} 
          {...register(`active`)} 
        />
      </>
    </FlexibleWidthDialog>
  </>)
}