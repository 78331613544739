import { useState } from "react";
import { Button, CircularProgress, InputLabel, Switch, TextField } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'

import { ValidationSchema } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { saveCity } from "../../../services/api/City";

export default function Add({ open, close, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors } }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  const mutation = useMutation(saveCity, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleAdd = (params) => { 
    //console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate(params)
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { 
    setDataInstance({...dataInstance, [event.target.name]: event.target.value}) 
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({...dataInstance, [name]: !dataInstance[name]}) 
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Nueva ciudad`} actions={<Actions />}>
      <>
        <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataInstance.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <InputLabel htmlFor='slug' required style={{ fontSize: 14 }}>Slug</InputLabel>
        <TextField id='slug' fullWidth margin="dense" type="text" name='slug'
          value={dataInstance.slug || ""}
          {...register(`slug`)} 
          error={errors.slug ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.slug?.message} />
        <br />
        <InputLabel htmlFor='activeInAmigoSadasi' required style={{ fontSize: 14 }}>Activa en Amigo Sadasi?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInAmigoSadasi` }}
          onClick={() => handleChangeBoolean('activeInAmigoSadasi')}
          checked={dataInstance.activeInAmigoSadasi || false} 
          {...register(`activeInAmigoSadasi`)} 
        />
        <br />
        <InputLabel htmlFor='activeInAmigoSadasiRJR' required style={{ fontSize: 14 }}>Activa en Amigo Sadasi RJR?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInAmigoSadasiRJR` }}
          onClick={() => handleChangeBoolean('activeInAmigoSadasiRJR')}
          checked={dataInstance.activeInAmigoSadasiRJR || false} 
          {...register(`activeInAmigoSadasiRJR`)} 
        />
        <br />
        <InputLabel htmlFor='activeInAlttaHomes' required style={{ fontSize: 14 }}>Activa en AlttaHomes?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInAlttaHomes` }}
          onClick={() => handleChangeBoolean('activeInAlttaHomes')}
          checked={dataInstance.activeInAlttaHomes || false} 
          {...register(`activeInAlttaHomes`)} 
        />
        <br />
        <InputLabel htmlFor='activeInHeroes' required style={{ fontSize: 14 }}>Activa en Héroes?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInHeroes` }}
          onClick={() => handleChangeBoolean('activeInHeroes')}
          checked={dataInstance.activeInHeroes || false} 
          {...register(`activeInHeroes`)} 
        />
        <br />
        <InputLabel htmlFor='activeInMiHogar' required style={{ fontSize: 14 }}>Activa en Mi Hogar en el caribe?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInMiHogar` }}
          onClick={() => handleChangeBoolean('activeInMiHogar')}
          checked={dataInstance.activeInMiHogar || false} 
          {...register(`activeInMiHogar`)} 
        />
        <br />
        <InputLabel htmlFor='active' required style={{ fontSize: 14 }}>Activa?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={() => handleChangeBoolean('active')}
          checked={dataInstance.active || false} 
          {...register(`active`)} 
        />
      </>
    </FlexibleWidthDialog>
  </>)
}