import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function FlexibleWidthDialog({ open, title, maxWidth="sm", children, actions }) {
  return (
    <Dialog 
      fullWidth
      maxWidth={maxWidth}
      open={open}
      scroll={`paper`} 
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 18 }}> {title} </DialogTitle>
      <DialogContent dividers={true}> {children} </DialogContent>
      <DialogActions> {actions} </DialogActions>
    </Dialog>
  )
}