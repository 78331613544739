import { Dashboard as DashboardIcon } from '@mui/icons-material';

export const MenuItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    to: "/admin/dashboard",
    role: ["SUPADMIN", "ADMIN", "SADASIUSER"]
  },
  {
    text: "Actualizar Precios",
    icon: <DashboardIcon />,
    to: "/admin/update-products",
    role: ["SUPADMIN", "ADMIN", "SADASIUSER"]
  },
  {
    text: "Ciudades",
    icon: <DashboardIcon />,
    to: "/admin/cities",
    role: ["SUPADMIN", "ADMIN"]
  },
  {
    text: "Regiones",
    icon: <DashboardIcon />,
    to: "/admin/regions",
    role: ["SUPADMIN", "ADMIN"]
  },
  {
    text: "Tipos de Crédito",
    icon: <DashboardIcon />,
    to: "/admin/credits",
    role: ["SUPADMIN", "ADMIN"]
  },
  {
    text: "Tipos de Producto",
    icon: <DashboardIcon />,
    to: "/admin/kind-products",
    role: ["SUPADMIN", "ADMIN"]
  },
  /* {
    text: "Desarrollos",
    icon: <DashboardIcon />,
    to: "/admin/real-state-developments",
    role: ["SUPADMIN", "ADMIN"]
  }, */
  {
    text: "Desarrollos",
    icon: <DashboardIcon />,
    to: "/admin/real-state-developments",
    role: ["SUPADMIN", "ADMIN"]
  },
  {
    text: "Productos",
    icon: <DashboardIcon />,
    to: "/admin/products",
    role: ["SUPADMIN", "ADMIN"]
  },
  {
    text: "Banners",
    icon: <DashboardIcon />,
    to: "/admin/banners",
    role: ["SUPADMIN", "ADMIN"]
  },
  {
    text: "Banners Multiple Links",
    icon: <DashboardIcon />,
    to: "/admin/banners-multiple-links",
    role: ["SUPADMIN", "ADMIN"]
  },
  {
    text: "Redirecciones",
    icon: <DashboardIcon />,
    to: "/admin/redirects",
    role: ["SUPADMIN", "ADMIN"]
  },
  {
    text: "Usuarios",
    icon: <DashboardIcon />,
    to: "/admin/users",
    role: ["SUPADMIN"]
  },
  {
    text: "Reporte WhatsApp TrackingClicks",
    icon: <DashboardIcon />,
    to: "/admin/reports/whatsapp-tracking",
    role: ["SUPADMIN", "ADMIN"]
  },
]