import { useContext, useEffect, useState } from "react";
import { Divider, Fab, Grid, IconButton, InputBase, List, Paper, Tooltip, Typography } from "@mui/material";
import { Add as AddIcon, Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import RSDlist from "components/List/RSDList";
import { useQuery, useQueryClient } from "react-query"
import { getBasicListRealStateDevelopments, getRealStateDevelopmentById } from "services/api/RealStateDevelopment"
import { ValidateNullUndefinedEmpty } from "services/helpers/DataValidations";
import { SnackbarGlobal } from "components/Snackbar";
import { actionsAlttaHomes, actionsHeroes, actionsHome } from "./const";
import { getRegions } from "services/api/Region";
import { getCities } from "services/api/City";
import { getKindProducts } from "services/api/KindProduct";
import { getCredits } from "services/api/Credit";
import Edit from "./Edit";
import Remove from "./Remove";
import ImagesGallery from "./ImagesGallery";
import VirtualAppointment from "./VirtualAppointment";
import SalesupTokens from "./SalesupTokens";
import HowToGet from "./HowToGet";
import Amenities from "./Amenitites";
import Add from "./Add";
import LoaderRSDOptions from "components/Loaders/RSDAdmin";
import { AuthContext } from "context/AuthContext";
import Code from "./Code";
import AlttaHomes from "./AlttaHomes";
import Heroes from "./Heroes";
import CRMProperties from "./CRMProperties";
import Chatbot from "./Chatbot";

export default function RealStateDevelopments() {
  const { setTitlePage } = useContext(AuthContext);
  const [dataFilter, setDataFilter] = useState(null)
  const [idSelected, setIdSelected] = useState(null)
  const [instanceSelected, setInstanceSelected] = useState({})
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: image gallery, 4: amenities, 5: Virtual shedules, 6: routes, 7: tokens salesup, 8: delete
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const queryClient = useQueryClient()
  const { /* isLoading, isFetching, */  error, data } = useQuery(
    'realStateDevelopmentBasicList', getBasicListRealStateDevelopments, { refetchOnWindowFocus: false }
  )
  const { isLoading: iLrsdOne, isFetching: iFrsdOne, data: rsdOne } = useQuery(
    ['realStateDevelopmentById', idSelected],
    () => getRealStateDevelopmentById(idSelected),
    { refetchOnWindowFocus: false, enabled: (idSelected !== null && idSelected !== undefined) }
  )
  const { data: dRegions } = useQuery('regionsList', getRegions, { refetchOnWindowFocus: false })
  const { data: dCities } = useQuery('citiesList', getCities, { refetchOnWindowFocus: false })
  const { data: dKindProducts } = useQuery('kindProductsList', getKindProducts, { refetchOnWindowFocus: false })
  const { data: dCredits } = useQuery('creditsList', getCredits, { refetchOnWindowFocus: false })

  useEffect(() => {
    setTitlePage('Desarrollos');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => { if (data !== undefined && data !== null && data !== "") setDataFilter(data.data) }, [data])
  useEffect(() => { if (rsdOne !== undefined && rsdOne !== null && rsdOne !== "") setInstanceSelected(rsdOne.data) }, [rsdOne])

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const handlerSelectedRSD = (id) => setIdSelected(id)
  const handleRefreshData = () => { queryClient.invalidateQueries('realStateDevelopmentById') }
  const handleResetActions = () => { setOpenAction(0) }

  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    if (success) { handleResetActions(); handleRefreshData() }
  }

  if (error) return <div>Ha ocurrido un error</div>

  return (<>
    <div className="rsd-container">
      <div className="rsd-left-siderbar-container">
        <Paper sx={{ p: '2px 4px', mb: 1, display: 'flex', alignItems: 'center', width: '100%' }} >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Buscar" onChange={handleSearch} inputProps={{ 'aria-label': 'search' }} />
          <SearchIcon style={{ margin: 10 }} />
          {idSelected === null ? <>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <Tooltip title="Crear desarrollo"><AddIcon style={{ margin: 10, cursor: "pointer" }} onClick={() => setOpenAction(1)} /></Tooltip>
          </> : null}
        </Paper>
        <div className="rsd-left-siderbar-main">
          {(dataFilter !== undefined && dataFilter !== null) ? <>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {dataFilter.map((rsd, index) => (
                <RSDlist key={`${index}-${rsd._id}`} {...rsd} onClick={() => handlerSelectedRSD(rsd._id)} isFocus={rsd._id === idSelected} />
              ))}
            </List>
          </> : null}
        </div>
      </div>
      <div className="rsd-main">
        {idSelected === null ? <p>Selecciona un desarrollo para poder acceder a sus opciones</p> : null}
        {iLrsdOne && iFrsdOne ? <LoaderRSDOptions /> :
          <div>
            {ValidateNullUndefinedEmpty(rsdOne) && rsdOne.hasOwnProperty('data') ? <>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", position: "sticky", top: 64, backgroundColor: "white", zIndex: 2, paddingBottom: 30 }} >
                <img src={instanceSelected.logo} style={{ height: 60 }} alt="logotipo" />
                <span className="fab_close">
                  <Tooltip title="Cerrar opciones"><Fab color="primary" size="small" onClick={() => setIdSelected(null)}><CloseIcon /></Fab></Tooltip>
                </span>
              </div>
              <Grid container spacing={2}>
                {actionsHome.map((action, index) => (
                  <Grid key={`${index}-${action.name}`} item xs={6} sm={6} md={4} lg={3} style={{ textAlign: "center" }}>
                    <IconButton onClick={() => setOpenAction(action.action)}>{action.icon}</IconButton> <p>{action.name}</p>
                  </Grid>
                ))}

                {instanceSelected.activeInAlttaHomes ? <> 
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="h6" >Altta Homes</Typography>
                  </Grid>
                  {actionsAlttaHomes.map((action, index) => (
                  <Grid key={`${index}-${action.name}`} item xs={6} sm={6} md={4} lg={3} style={{ textAlign: "center" }}>
                    <IconButton onClick={() => setOpenAction(action.action)}>{action.icon}</IconButton> <p>{action.name}</p>
                  </Grid>
                  ))}
                </> : null}

                {instanceSelected.activeInHeroes ? <> 
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="h6" >Héroes</Typography>
                  </Grid>
                  {actionsHeroes.map((action, index) => (
                  <Grid key={`${index}-${action.name}`} item xs={6} sm={6} md={4} lg={3} style={{ textAlign: "center" }}>
                    <IconButton onClick={() => setOpenAction(action.action)}>{action.icon}</IconButton> <p>{action.name}</p>
                  </Grid>
                  ))}
                </> : null}
              </Grid>
            </> : null}
          </div>}
      </div>
    </div>
    <Add open={openAction === 1} close={() => handleResetActions()} reportAction={handleWatchAction} extraData={{ dRegions, dCities, dKindProducts, dCredits }} />
    <Edit open={openAction === 2} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} extraData={{ dRegions, dCities, dKindProducts, dCredits }} />
    <ImagesGallery open={openAction === 3} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <Amenities open={openAction === 4} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <VirtualAppointment open={openAction === 5} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <HowToGet open={openAction === 6} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <SalesupTokens open={openAction === 7} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <Remove open={openAction === 8} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <Code open={openAction === 9} close={() => handleResetActions()} instance={instanceSelected} />
    <AlttaHomes open={openAction === 10} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <Heroes open={openAction === 11} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <CRMProperties open={openAction === 12} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <Chatbot open={openAction === 13} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <SnackbarGlobal vertical='bottom' horizontal='center' open={notification.open} close={() => setNotification({ open: false, message: "", severity: "" })} message={notification.message} severity={notification.severity} />
  </>)
}