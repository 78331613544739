import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getRoles = () => 
  fetch(
    `${API_BASE_URL}/roles`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  .then(res => res.json())