import { InputLabel, MenuItem, Select } from "@mui/material"

export const MonthSelect = ({ value, onChange }) => {
  const months_list = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

  return <>
    <InputLabel id="month-simple-select-label">Mes:</InputLabel>
    <Select
      labelId="month-simple-select-label"
      id="month-simple-select"
      value={value}
      onChange={onChange}
    >
      {months_list.map((ml, index) => (
        <MenuItem key={`${ml}-${index + 1}`} value={index + 1}>{ml}</MenuItem>
      ))}
    </Select>
  </>
}

const arrayYearRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

export const YearSelect = ({ yearStart, yearStop, yearStep, value, onChange }) => (
  <>
    <InputLabel id="year-simple-select-label">Año:</InputLabel>
    <Select
      labelId="month-simple-select-label"
      id="month-simple-select"
      value={value}
      onChange={onChange}
    >
      {arrayYearRange(yearStart, yearStop, yearStep).map((yr, index) => (
        <MenuItem key={`${yr}-${index + 1}`} value={yr}>{yr}</MenuItem>
      ))}
    </Select>
  </>
)