import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useQuery } from 'react-query'
import { JsonToExcel } from "react-json-to-excel"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { getProductsDataForExcel } from "services/api/Download";

export default function Download({ open, close }) {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])
  const { data } = useQuery('list', getProductsDataForExcel, { refetchOnWindowFocus: false, enabled: open })

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setIsLoading(false)
      setList(data.data)
    }
  }, [data])

  const handleClose = () => { close() }

  const Actions = () => (<>
    {!isLoading ? <Button onClick={handleClose}>Cancelar</Button> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title="Descargar" actions={<Actions />}>
      { !isLoading ?
      <JsonToExcel
        title="Descargar como archivo Excel"
        data={list}
        fileName="precio-productos-sadasi"
      />
      : <CircularProgress /> }
    </FlexibleWidthDialog>
  </>)
}