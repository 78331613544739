import { useQuery, useQueryClient } from "react-query";
import { Fab, InputBase, List, Paper, Tooltip } from "@mui/material";
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import RSDlist from "components/List/RSDList";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { getBasicListRealStateDevelopments } from "services/api/RealStateDevelopment";
import { getWhatsAppTrackingByRealStateDevelopment } from "services/api/WhatsAppTracking";
import LoaderRSDOptions from "components/Loaders/RSDAdmin";
import { ValidateNullUndefinedEmpty } from "services/helpers/DataValidations";
import { MonthSelect, YearSelect } from "./modules/Selects";
import TableCustom from "./modules/Table";

export default function WhastAppTrakingButton() {
  const { setTitlePage } = useContext(AuthContext);
  const [dataFilter, setDataFilter] = useState(null)
  const [idSelected, setIdSelected] = useState(null)
  const [rsdSelected, setRsdSelected] = useState(null)
  const [monthSelected, setMonthSelected] = useState(null)
  const [yearSelected, setYearSelected] = useState(null)
  const queryClient = useQueryClient()
  const { error, data } = useQuery(
    'realStateDevelopmentBasicList', getBasicListRealStateDevelopments, { refetchOnWindowFocus: false }
  )
  const { isLoading, isFetching, data: dWAT } = useQuery(
    ['watData', idSelected, monthSelected, yearSelected],
    () => getWhatsAppTrackingByRealStateDevelopment({ id: idSelected, month: monthSelected, year: yearSelected }),
    { refetchOnWindowFocus: false, enabled: (idSelected !== undefined && monthSelected !== null && yearSelected !== null) }
  )
  const date = new Date();

  useEffect(() => {
    setTitlePage('Reportes / WhatsApp Tracking Clicks');
    setYearSelected(date.getFullYear())
    setMonthSelected(date.getMonth() + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => { if (data !== undefined && data !== null && data !== "") setDataFilter(data.data) }, [data])

  useEffect(() => {
    queryClient.invalidateQueries('watData')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected, monthSelected, yearSelected])

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const handlerSelectedRSD = (rsd) => { setIdSelected(rsd._id); setRsdSelected(rsd) }

  if (error) return <div>Ha ocurrido un error</div>

  return (
    <div className="rsd-container">
      <div className="rsd-left-siderbar-container">
        <Paper sx={{ p: '2px 4px', mb: 1, display: 'flex', alignItems: 'center', width: '100%' }} >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Buscar" onChange={handleSearch} inputProps={{ 'aria-label': 'search' }} />
          <SearchIcon style={{ margin: 10 }} />
        </Paper>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
          <MonthSelect value={monthSelected} onChange={(event) => setMonthSelected(event.target.value)} />
          <YearSelect yearStart={date.getFullYear() - 5} yearStop={date.getFullYear()} yearStep={1} value={yearSelected} onChange={(event) => setYearSelected(event.target.value)} />
        </div>
        <div className="rsd-left-siderbar-main">
          {(dataFilter !== undefined && dataFilter !== null) ? <>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {dataFilter.map((rsd, index) => (
                <RSDlist key={`${index}-${rsd._id}`} {...rsd} onClick={() => handlerSelectedRSD(rsd)} isFocus={rsd._id === idSelected} />
              ))}
            </List>
          </> : null}
        </div>
      </div>
      <div className="rsd-main">
        {isLoading && isFetching ? <LoaderRSDOptions /> :
          <> {rsdSelected !== null ?
            <div>
              {ValidateNullUndefinedEmpty(dWAT) && dWAT.hasOwnProperty('data') ? <>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", backgroundColor: "white", zIndex: 2, paddingBottom: 30 }} >
                  <img src={rsdSelected.logo} style={{ height: 60 }} alt="logotipo-desarrollo" />
                  <span className="fab_close">
                    <Tooltip title="Cerrar"><Fab color="primary" size="small" onClick={() => { setIdSelected(null); setRsdSelected(null) }}><CloseIcon /></Fab></Tooltip>
                  </span>
                </div>
                {dWAT.data.length > 0 ?
                  <div>
                    <p>Total del clicks en el mes: <b>{dWAT.data[0].total_clicks_per_month}</b></p>
                    <TableCustom
                      config={[
                        { head: "Día", align: "left", key: "day" },
                        { head: "Clicks", align: "right", key: "clicks" }
                      ]}
                      data={dWAT.data[0].data[0].clicks} />
                  </div> : <p>No se encontraron registros</p>}
              </> : null}
            </div> : <>
              {dWAT !== undefined && dWAT.data.length > 0 ?
                <div>
                  <p>Total del clicks en el mes: <b>{dWAT.data[0].total_clicks_per_month}</b></p>
                  <TableCustom
                    config={[
                      { head: "Desarrollo", align: "left", key: "real_state_development" },
                      { head: "Clicks", align: "right", key: "total_clicks" }
                    ]}
                    data={dWAT.data[0].data} />
                </div> : <p>No se encontraron registros</p>}
            </>
          } </>
        }
      </div>
    </div>
  )
}