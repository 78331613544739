import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, InputLabel, Switch, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'
import { ValidationSchemaChatbot } from "../services/SchemaValidation"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { editRealStateDevelopmentChatbot } from "services/api/RealStateDevelopment";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { useForm } from "react-hook-form";
import { Preview } from "./Preview";

export default function Chatbot({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState([])
  let { reset, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(ValidationSchemaChatbot)
  })

  useEffect(() => {
    if (open) {
      console.log("Chatbot", instance.chatbot)
      const schemaChatbotData = {
        active: instance.chatbot !== undefined ? instance.chatbot.active : false,
        title: instance.chatbot !== undefined ? instance.chatbot.title : `Chatbot - ${instance.name}`,
        color_button_titlebar_color: instance.chatbot !== undefined ? instance.chatbot.color_button_titlebar_color : "#FD671A",
        color_button_titlebar_font_color: instance.chatbot !== undefined ? instance.chatbot.color_button_titlebar_font_color : "#ffffff",
        color_chat_background_color: instance.chatbot !== undefined ? instance.chatbot.color_chat_background_color : "#fafafa",
        color_font_color: instance.chatbot !== undefined ? instance.chatbot.color_font_color : "#000000",
        color_bot_message: instance.chatbot !== undefined ? instance.chatbot.color_bot_message : "#ffffff",
        color_user_message: instance.chatbot !== undefined ? instance.chatbot.color_user_message : "#dedede",
        color_send_icon: instance.chatbot !== undefined ? instance.chatbot.color_send_icon : "#FD671A"
      }
      setDataInstance(instance.chatbot !== undefined ? instance.chatbot : schemaChatbotData)
      reset(instance.chatbot !== undefined ? instance.chatbot : schemaChatbotData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(editRealStateDevelopmentChatbot, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleEdit = (params) => {
    console.log("prueba chatbot", params)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: params })
  }

  const handleClose = () => { reset(); setDataInstance([]); close() }

  const handleChangeData = (event) => { //console.log("handleChangeDataSelect", event.target.value)
    if (event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeDataBoolean = (data) => { setDataInstance({ ...dataInstance, [data]: !dataInstance[data] }) }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog maxWidth="md" open={open} actions={<Actions />} title={<>Chatbot - {instance.name} </>} >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='active' required style={{ fontSize: 14 }}>Activar</InputLabel>
          <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
            onClick={() => handleChangeDataBoolean('active')}
            checked={dataInstance.active || false}
            {...register(`active`)}
          />
          <br />
          <InputLabel htmlFor="title" required style={{ fontSize: 14 }}>Título</InputLabel>
          <TextField id="title" fullWidth margin="dense" type="text" size="small"
            value={dataInstance.title || ""}
            {...register(`title`)}
            error={errors.title ? true : false}
            onChange={(event) => handleChangeData(event)} />
          <ValidateErrorMessage message={errors.title?.message} />
          <br />
          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <InputLabel htmlFor="color_button_titlebar_color" required style={{ fontSize: 14 }}>Color de la barra del Título</InputLabel>
              <TextField id="color_button_titlebar_color" sx={{ width: 50 }} margin="dense" type="color" size="small"
                value={dataInstance.color_button_titlebar_color || ""}
                {...register(`color_button_titlebar_color`)}
                error={errors.color_button_titlebar_color ? true : false}
                onChange={(event) => handleChangeData(event)} />
            </div>
            <ValidateErrorMessage message={errors.color_button_titlebar_color?.message} />
          </div>
          <br />
          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <InputLabel htmlFor="color_button_titlebar_font_color" required style={{ fontSize: 14 }}>Color del texto de la barra del Título</InputLabel>
              <TextField id="color_button_titlebar_font_color" sx={{ width: 50 }} margin="dense" type="color" size="small"
                value={dataInstance.color_button_titlebar_font_color || ""}
                {...register(`color_button_titlebar_font_color`)}
                error={errors.color_button_titlebar_font_color ? true : false}
                onChange={(event) => handleChangeData(event)} />
            </div>
            <ValidateErrorMessage message={errors.color_button_titlebar_font_color?.message} />
          </div>
          <br />
          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <InputLabel htmlFor="color_chat_background_color" required style={{ fontSize: 14 }}>Color de fondo</InputLabel>
              <TextField id="color_chat_background_color" sx={{ width: 50 }} margin="dense" type="color" size="small"
                value={dataInstance.color_chat_background_color || ""}
                {...register(`color_chat_background_color`)}
                error={errors.color_chat_background_color ? true : false}
                onChange={(event) => handleChangeData(event)} />
            </div>
            <ValidateErrorMessage message={errors.color_chat_background_color?.message} />
          </div>
          <br />
          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <InputLabel htmlFor="color_font_color" required style={{ fontSize: 14 }}>Color de fuente</InputLabel>
              <TextField id="color_font_color" sx={{ width: 50 }} margin="dense" type="color" size="small"
                value={dataInstance.color_font_color || ""}
                {...register(`color_font_color`)}
                error={errors.color_font_color ? true : false}
                onChange={(event) => handleChangeData(event)} />
            </div>
            <ValidateErrorMessage message={errors.color_font_color?.message} />
          </div>
          <br />
          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <InputLabel htmlFor="color_bot_message" required style={{ fontSize: 14 }}>Color fondo de mensaje de bot</InputLabel>
              <TextField id="color_bot_message" sx={{ width: 50 }} margin="dense" type="color" size="small"
                value={dataInstance.color_bot_message || ""}
                {...register(`color_bot_message`)}
                error={errors.color_bot_message ? true : false}
                onChange={(event) => handleChangeData(event)} />
            </div>
            <ValidateErrorMessage message={errors.color_bot_message?.message} />
          </div>
          <br />
          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <InputLabel htmlFor="color_user_message" required style={{ fontSize: 14 }}>Color fondo de mensaje de usuario</InputLabel>
              <TextField id="color_user_message" sx={{ width: 50 }} margin="dense" type="color" size="small"
                value={dataInstance.color_user_message || ""}
                {...register(`color_user_message`)}
                error={errors.color_user_message ? true : false}
                onChange={(event) => handleChangeData(event)} />
            </div>
            <ValidateErrorMessage message={errors.color_user_message?.message} />
          </div>
          <br />
          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <InputLabel htmlFor="color_send_icon" required style={{ fontSize: 14 }}>Color ícono de envío</InputLabel>
              <TextField id="color_send_icon" sx={{ width: 50 }} margin="dense" type="color" size="small"
                value={dataInstance.color_send_icon || ""}
                {...register(`color_send_icon`)}
                error={errors.color_send_icon ? true : false}
                onChange={(event) => handleChangeData(event)} />
            </div>
            <ValidateErrorMessage message={errors.color_send_icon?.message} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom component="div">Vista previa</Typography>
          <Preview {...dataInstance} />
        </Grid>
      </Grid>
    </FlexibleWidthDialog >
  </>)
}