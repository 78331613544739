import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getRealStateDevelopments = () =>
  fetch(
    `${API_BASE_URL}/real-state-developments`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  .then(res => res.json())

export const getBasicRealStateDevelopments = () =>
  fetch(
    `${API_BASE_URL}/real-state-developments/basic-get-all`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  .then(res => res.json())

  export const getBasicListRealStateDevelopments = () =>
  fetch(
    `${API_BASE_URL}/real-state-developments/basic-list`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  .then(res => res.json())

export const getRealStateDevelopmentById = async (id) => {
    const res = await fetch(
      `${API_BASE_URL}/real-state-developments/${id}`,
      {
        method: 'GET',
        headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
      }
    )
    return await res.json()
  }

export const saveRealStateDevelopment = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editRealStateDevelopment = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const deleteRealStateDevelopment = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}`,
    {
      method: 'DELETE',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  return await res.json()
}

/* Special Update */
// Amenities
export const editRealStateDevelopmentAmenities = async (data) => {
  const { id, params } = data
  params.amenities_list.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/amenities`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Image Gallery
export const editRealStateDevelopmentImageGallery = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/image-gallery`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Virtual Appointment
export const editRealStateDevelopmentVirtualAppointment = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/virtual-appointment`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// How To Get
export const editRealStateDevelopmentHowToGet = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/how-to-get`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Salesup Tokens
export const editRealStateDevelopmentSalesupTokens = async (data) => {
  const { id, params } = data
  if(params.salesup_tokens.length > 0) {
    params.salesup_tokens.forEach(param => {
      delete param['id']
    })
  }
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/salesup-tokens`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// CRM Properties
export const editRealStateDevelopmentCRMProperties = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/crm-properties`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Chatbot
export const editRealStateDevelopmentChatbot = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/chatbot`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Altta Homes
export const editRealStateDevelopmentAlttaHomes = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/altta-homes`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Heroes
export const editRealStateDevelopmentHeroes = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/${id}/heroes`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Hash
export const generateProductHash = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/hash`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Salesup Tokens Bulk
export const editRealStateDevelopmentSalesupTokensBulk = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/salesup-tokens-bulk`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Virtual Appointments Bulk
export const editRealStateDevelopmentVirtualAppointmentsBulk = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/virtual-appointments-bulk`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Amenities Bulk
export const editRealStateDevelopmentAmenitiesBulk = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/amenities-bulk`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Structured Data Bulk
export const editRealStateDevelopmentStructuredDataBulk = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/real-state-developments/structured-data-bulk`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}