import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  slug: Yup.string().required("Campo requerido"),
  real_state_development: Yup.string().required("Campo requerido"),
  name: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido"),
  facade: Yup.string().required("Campo requerido"),
  square_meters_of_land: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  square_meters_of_construction: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  price: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  text_before_price: Yup.string().nullable(),
  text_after_price: Yup.string().nullable(),
  kind_product: Yup.string().required("Campo requerido"),
  is_coming_soon: Yup.boolean().required("Campo requerido"),
  is_available: Yup.boolean().required("Campo requerido"),
  brochure: Yup.string().nullable(),
  structured_data: Yup.string().nullable(),
  isRedirectActive: Yup.boolean().required("Campo requerido"),
  isRedirectToRealState: Yup.boolean().when('isRedirectActive', {
    is: true,
    then: Yup.boolean().required("Campo requerido"),
    otherwise: Yup.boolean()
  }),
  redirectCode: Yup.string().when('isRedirectActive', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  redirectUrl: Yup.string().when(['isRedirectActive', 'isRedirectToRealState'], {
    is: (isRedirectActive, isRedirectToRealState) => isRedirectActive && !isRedirectToRealState,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  active: Yup.boolean().required("Campo requerido")
})

// Image gallery validation
export const ValidationSchemaImageGallery = Yup.object().shape({
  image: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido")
})

export const ValidationSchemaArrayImageGallery = Yup.object({
  image_gallery: Yup.array().of(
    Yup.object().shape({
      image: Yup.string().required("Campo requerido"),
      description: Yup.string().required("Campo requerido")
    })
  )
})

// Architectural Plans validation
export const ValidationSchemaArchitecturalPlan = Yup.object().shape({
  image: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido")
})

export const ValidationSchemaArrayArchitecturalPlan = Yup.object({
  architectural_plans: Yup.array().of(
    Yup.object().shape({
      image: Yup.string().required("Campo requerido"),
      description: Yup.string().required("Campo requerido")
    })
  )
})

// Virtual Tours validation
export const ValidationSchemaVirtualTour = Yup.object().shape({
  description: Yup.string().required("Campo requerido"),
  link: Yup.string().required("Campo requerido")
})

export const ValidationSchemaArrayVirtualTour = Yup.object({
  virtual_tours: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("Campo requerido"),
      link: Yup.string().required("Campo requerido")
    })
  )
})

// Floors validation
export const ValidationSchemaFloorFeature = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  icon: Yup.string().required("Campo requerido"),
})

export const ValidationSchemaFloor = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  /*eatures: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Campo requerido"),
      icon: Yup.string().required("Campo requerido")
    })
  )*/
})

export const ValidationSchemaFloors = Yup.array().of(
  Yup.object({
    name: Yup.string().required("Campo requerido"),
    features: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Campo requerido"),
        icon: Yup.string().required("Campo requerido")
      })
    )
  })
)

// Features validation
export const ValidationSchemaFeature = Yup.object().shape({
  feature: Yup.string().required("Campo requerido"),
  quantity: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  icon: Yup.string().required("Campo requerido")
})

export const ValidationSchemaArrayFeature = Yup.object({
  features: Yup.array().of(
    Yup.object().shape({
      feature: Yup.string().required("Campo requerido"),
      quantity: Yup.number().typeError("Campo requerido").required("Campo requerido"),
      icon: Yup.string().required("Campo requerido")
    })
  )
})
