import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, CssBaseline, Divider, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuItems } from "./MenuData";
import { AuthContext } from "context/AuthContext";

const useStyles = makeStyles((theme) => ({
  optionDrawer: {
    textDecoration: 'none',
    color: '#000000'
  },
  toolbar: theme.mixins.toolbar,
}))

const Menu = ({ action }) => {
  const { logOut, globalUser } = useContext(AuthContext)
  const classes = useStyles()
  const location = useLocation()

  return (
    <>
    
    <Box
      className={classes.toolbar}
      sx={{ width: 251 }}
      role="presentation"
      onClick={ () => action() }
      onKeyDown={ () => action() }
    >
      <div style={{ backgroundColor: "#ffffff", textAlign: "center", marginBottom: 8 }}>
        <img src="/logo_sadasi.png" style={{ width: "100%", maxWidth: 200 }}/>
      </div>
      <List>
      </List>
        {MenuItems.map((item, index) => {
          if (item.role.includes(JSON.parse(globalUser).role.identifier)) {
            return (
            <Link to={item.to} key={`${index}-${item.text}`} className={classes.optionDrawer}>
            <ListItem button
              sx={{
                backgroundColor: (location.pathname === item.to) ? "#fff" : "#e76627",
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                '&:hover': {
                  backgroundColor: (location.pathname === item.to) ? "#fff" : "#b95a2b",
                }
              }}
            >
              <ListItemIcon 
                sx={{ 
                  color: (location.pathname === item.to) ? "#e76627" : "#fff"
                }}
              >{item.icon}</ListItemIcon>
              <ListItemText 
                primary={item.text} 
                sx={{ 
                  color: (location.pathname === item.to) ? "#e76627" : "#fff" 
                }} 
              />
            </ListItem>
          </Link>)
          }
        })}
        {/* {MenuItems.map((item, index) => (<>
          { item.role.includes(JSON.parse(globalUser).role.identifier) ?
            <Link to={item.to} key={`${index}-${item.text}`} className={classes.optionDrawer}>
            <ListItem button
              sx={{
                backgroundColor: (location.pathname === item.to) ? "#fff" : "#e76627",
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                '&:hover': {
                  backgroundColor: (location.pathname === item.to) ? "#fff" : "#b95a2b",
                }
              }}
            >
              <ListItemIcon 
                sx={{ 
                  color: (location.pathname === item.to) ? "#e76627" : "#fff"
                }}
              >{item.icon}</ListItemIcon>
              <ListItemText 
                primary={item.text} 
                sx={{ 
                  color: (location.pathname === item.to) ? "#e76627" : "#fff" 
                }} 
              />
            </ListItem>
          </Link> : null }
        </>))} */}
      <Divider />
      <List>
        <ListItem button onClick={logOut}>
          <ListItemText primary='Cerrar Sesión' />
        </ListItem>
      </List>
    </Box>
    </>
  );
};
export default Menu;
