import { useEffect, useState } from "react";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { Save as SaveIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon, Add as AddIcon } from "@mui/icons-material";
import { useMutation } from "react-query";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ValidationSchemaArrayFeature } from "../services/SchemaValidation";
import FullScreenDialog from "components/Dialog/FullScreenDialog";
import ImageGalleryCard from "components/Card/ImageLeftCard";
import { editProductFeatures } from "services/api/Product";
import Add from "./Add";
import Edit from "./Edit";
import Remove from "./Remove";

export default function Feature({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  const [position, setPosition] = useState(null)
  const [instanceSelected, setInstanceSelected] = useState({})
  let { /*handleSubmit: hTBP, formState: { errors: eTBP },*/ control: cTBP, setValue: svTBP, getValues } = useForm({
    resolver: yupResolver(ValidationSchemaArrayFeature),
    defaultValues: { 'features': [] }
  })
  const { fields: fTBP, append: aTBP, remove: rmvTBP, update: updTBP, move: mvTBP } = useFieldArray({
    name: 'features', control: cTBP
  })

  useEffect(() => {
    if (open) { svTBP('features', instance.features) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(editProductFeatures, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction('feature', false, { message: 'Error al editar los datos' }, false)
      } else {
        reportAction('feature', false, {}, true)
      }
    }
  })

  const onSubmit = () => { //console.log("onSubmit", fTBP) 
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: fTBP })
  }

  const handleClose = () => { close(); }

  const handleAddAP = (data) => { aTBP(data) }
  const handleLoadEditAP = (position, data) => {
    setPosition(position)
    setInstanceSelected(data)
  }
  const handleEditAP = (data) => {
    let dataTemp = { ...fTBP[position], feature: data.feature, quantity: data.quantity, icon: data.icon }
    updTBP(position, dataTemp)
  }
  const handleLoadRemoveAP = (position, data) => {
    setPosition(position)
    setInstanceSelected(data)
  }
  const handleRemoveAP = () => { rmvTBP(position) }
  const handlerChangePositionAP = (direction, position) => { mvTBP(position, direction === 'up' ? position - 1 : position + 1) }

  const Actions = ({ item, index, totalItems }) => (<>
    <IconButton aria-label="edit" onClick={() => { handleLoadEditAP(index, item); setOpenAction(2) }}> <EditIcon color="info" /> </IconButton>
    <IconButton aria-label="delete" onClick={() => { handleLoadRemoveAP(index, item); setOpenAction(3) }}> <DeleteIcon color='error' /> </IconButton>
    <div>
      {index !== 0 && <IconButton aria-label="up-position" onClick={() => handlerChangePositionAP("up", index)}> <ArrowUpwardIcon /> </IconButton>}
      {index !== totalItems - 1 && <IconButton aria-label="down-position" onClick={() => handlerChangePositionAP("down", index)}> <ArrowDownwardIcon /> </IconButton>}
    </div>
  </>)

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = getValues("features");
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    svTBP('features', updatedList)
  }

  return (<>
    <FullScreenDialog open={open} changeOpen={handleClose} title={`Características - ${instance.name}`} actions={<>
      {!isLoading ? <>
        <IconButton autoFocus color="inherit" onClick={() => setOpenAction(1)} aria-label="add"> <AddIcon /> </IconButton>
        <IconButton autoFocus color="inherit" onClick={onSubmit} aria-label="save"> <SaveIcon /> </IconButton>
      </> : <CircularProgress />}
    </>}>
      <div className="admin-image-gallery one-columns">
        <div className="list-container">
          {/* <div style={{ width: "200px" }}><pre>{JSON.stringify(fTBP, undefined, 2)}</pre></div> */}
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                  {(fTBP.length > 0) ? (
                    fTBP.map((item, i) => (
                      <Draggable key={item.id} draggableId={item.id} index={i}>
                        {(provided) => (
                          <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                            <ImageGalleryCard
                              image={item.icon}
                              description={<>{item.feature} ({item.quantity})</>}
                              actions={
                                <Actions item={item} index={i} totalItems={fTBP.length} />
                              }
                            />
                          </Grid>
                        )}
                      </Draggable>
                    ))) : <Grid item xs={12}> <Typography variant="inherit">No hay caracteristicas agregadas</Typography> </Grid>}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </FullScreenDialog>
    <Add open={openAction === 1} close={() => setOpenAction(0)} rsdId={instance._id} action={handleAddAP} />
    <Edit open={openAction === 2} close={() => setOpenAction(0)} rsdId={instance._id} instance={instanceSelected} action={handleEditAP} />
    <Remove open={openAction === 3} close={() => setOpenAction(0)} instance={instanceSelected} action={handleRemoveAP} />
  </>)
}