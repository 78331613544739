import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getProductsDataForExcel = () =>
  fetch(
    `${API_BASE_URL}/downloads/get-products-data-for-excel`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  .then(res => res.json())

  export const getProductsDataForExcelWebsite = () =>
  fetch(
    `${API_BASE_URL}/web-downloads/get-products-data-for-excel`,
    {
      method: 'GET',
    }
  )
  .then(res => res.json())