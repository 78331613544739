import { useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useMutation } from 'react-query'
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { deleteCredit } from "services/api/Credit";

export default function Remove({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)

  const mutation = useMutation(deleteCredit, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleRemove = () => { 
    setIsLoading(true)
    mutation.mutate(instance._id)
  }

  const handleClose = () => { close() }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="error" onClick={ () => handleRemove() }>Eliminar</Button>
      </> : <CircularProgress /> }
  </>)

  return (
    <FlexibleWidthDialog open={open} title="Eliminar" actions={<Actions />}>
      <Typography variant="body1" component="p">
        ¿Estas seguro de quere eliminar <b>{instance.name}</b>?
      </Typography>
    </FlexibleWidthDialog>
  )
}