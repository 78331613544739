import { Autocomplete, InputLabel, Switch, TextField, Typography } from "@mui/material";

import { ValidateErrorMessage } from "components/ValidateErrorMessage";

export default function GeneralDataForm({ dataInstance, formAttributes, changeDataInstance }) {

  const handleChangeData = (field, value) => { changeDataInstance("general", field, value) }

  return ( <>
    <Typography variant="h6" gutterBottom>Datos Generales</Typography><br />
    <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
    <TextField id='name' fullWidth margin="dense" type="text" name='name'
      value={dataInstance.name || ""}
      {...formAttributes.register(`name`)} 
      error={formAttributes.errors.name ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.name?.message} />
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <TextField id='description' fullWidth margin="dense" type="text" name='description' multiline maxRows={5}
      value={dataInstance.description || ""}
      {...formAttributes.register(`description`)} 
      error={formAttributes.errors.description ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.description?.message} />
    <br />
    <InputLabel htmlFor='show_in_home' required style={{ fontSize: 14 }}>Aparece en home?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-show_in_home` }}
      onClick={ () => handleChangeData('show_in_home', !dataInstance.show_in_home) }
      checked={dataInstance.show_in_home || false} 
      {...formAttributes.register(`show_in_home`)} 
    />
    <br />
    <InputLabel htmlFor='show_in_home_alttahomes' required style={{ fontSize: 14 }}>Aparece en home en Altta Homes?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-show_in_home_alttahomes` }}
      onClick={ () => handleChangeData('show_in_home_alttahomes', !dataInstance.show_in_home_alttahomes) }
      checked={dataInstance.show_in_home_alttahomes || false} 
      {...formAttributes.register(`show_in_home_alttahomes`)} 
    />
    <br />
    <InputLabel htmlFor='show_in_home_heroes' required style={{ fontSize: 14 }}>Aparece en home en Héroes?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-show_in_home_heroes` }}
      onClick={ () => handleChangeData('show_in_home_heroes', !dataInstance.show_in_home_heroes) }
      checked={dataInstance.show_in_home_heroes || false} 
      {...formAttributes.register(`show_in_home_heroes`)} 
    />
    <br />
    <InputLabel htmlFor='show_in_home_mihogar' required style={{ fontSize: 14 }}>Aparece en home en Mi hogar en el Caribe?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-show_in_home_mihogar` }}
      onClick={ () => handleChangeData('show_in_home_mihogar', !dataInstance.show_in_home_mihogar) }
      checked={dataInstance.show_in_home_mihogar || false} 
      {...formAttributes.register(`show_in_home_mihogar`)} 
    />
    <br />
    <InputLabel htmlFor='text_instructions' required style={{ fontSize: 14 }}>Instrucciones</InputLabel>
    <TextField id='text_instructions' fullWidth margin="dense" type="text" name='text_instructions' multiline maxRows={5}
      value={dataInstance.text_instructions || ""}
      {...formAttributes.register(`text_instructions`)} 
      error={formAttributes.errors.text_instructions ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.text_instructions?.message} />
    <br />
    <InputLabel htmlFor='background_color' required style={{ fontSize: 14 }}>Color de fondo del contenedor</InputLabel>
    <TextField id='background_color' fullWidth margin="dense" type="text" name='background_color' multiline maxRows={5}
      value={dataInstance.background_color || ""}
      {...formAttributes.register(`background_color`)} 
      error={formAttributes.errors.background_color ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.background_color?.message} />
    <br />
    <InputLabel htmlFor='text_color' required style={{ fontSize: 14 }}>Color de texto de las instrucciones</InputLabel>
    <TextField id='text_color' fullWidth margin="dense" type="text" name='text_color' multiline maxRows={5}
      value={dataInstance.text_color || ""}
      {...formAttributes.register(`text_color`)} 
      error={formAttributes.errors.text_color ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.description?.message} />
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Activa?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
      onClick={ () => handleChangeData('active', !dataInstance.active) }
      checked={dataInstance.active || false} 
      {...formAttributes.register(`active`)} 
    />
  </> )
}