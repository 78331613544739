import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getProducts = () =>
  fetch(
    `${API_BASE_URL}/products`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  .then(res => res.json())


export const saveProduct = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/products`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editProduct = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/products/${id}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const deleteProduct = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/products/${id}`,
    {
      method: 'DELETE',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  return await res.json()
}

/* Special Search */
// Get Products By Real State Development
export const getProductsByRealStateDevelopment = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/products/real-state-development/${id}`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  return await res.json()
}

// Get Products By Real State Development for Update Product by Sadasi Users
export const getProductsByRealStateDevelopmentSadasiUsers = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/products/real-state-development-sadasi-users/${id}`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  return await res.json()
}

/* Special Update */
// Image Gallery
export const editProductImageGallery = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/products/${id}/image-gallery`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Architectural Plans
export const editProductArchitecturalPlans = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/products/${id}/architectural-plans`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Virtual Tours
export const editProductVirtualTours = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/products/${id}/virtual-tours`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Floors
export const editProductFloors = async (data) => {
    const { id, params } = data
    params.forEach(param => {
      delete param['id']
    })
    params.forEach(param => {
      if(param.features !== undefined && param.features.length > 0) {
        param.features.forEach(feature => {
          delete feature['id']
        })
      }
    })
    const res = await fetch(
      `${API_BASE_URL}/products/${id}/floors`,
      {
        method: 'PUT',
        headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
        body: JSON.stringify(params)
      }
    )
    return await res.json()
  }

// Architectural Plans
export const editProductFeatures = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/products/${id}/features`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Orders
export const editProductOrder = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/products/order`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Price
export const editProductPrice = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/products/${id}/price`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Minimum Recovery Value
export const editProductMinimumRecoveryValue = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/products/${id}/minimum-recovery-value`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Hash
export const generateProductHash = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/products/hash`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Virtual Tours Bulk
export const editProductVirtualToursBulk = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/products/virtual-tours-bulk`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Floors Bulk
export const editProductFloorsBulk = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/products/floors-bulk`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

// Features Bulk
export const editProductFeaturesBulk = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/products/features-bulk`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}