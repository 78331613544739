import { useState } from "react";
import { IconButton } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import Download from "./Download";

export default function WebDownload() {
  const [open, setOpen] = useState(false);

  return (<>
    <IconButton onClick={() => setOpen(true)}><DownloadIcon style={{ margin: 10 }} /></IconButton>
    <Download open={open} close={() => setOpen(false)} />
  </>)
}