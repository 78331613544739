import { yupResolver } from "@hookform/resolvers/yup";
import { Button, IconButton, InputLabel, TextField } from "@mui/material";
import { Image as ImageIcon} from "@mui/icons-material"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { ValidationSchemaVirtualAppointment } from "domain/RealStateDevelopment/services/SchemaValidation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ImageInput from "components/Image/ImageInput";
import MediaModal from "domain/Media/MediaModal";

export default function Add({ open, close, action }) {
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaVirtualAppointment) 
  })

  const handleClose = () => {
    setDataInstance({})
    reset()
    close()
  }

  const handleAdd = (params) => {
    //console.log(params)
    action(params)
    handleClose()
  }

  const handleChangeData = (event) => { setDataInstance({...dataInstance, [event.target.name]: event.target.value}) }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const Actions = () => (<>
    <Button onClick={handleClose}>Cancelar</Button>
    <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
  </> )

  return (<>
    <FlexibleWidthDialog open={open} title="Agregar cita" actions={<Actions />}>
      <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
        <ImageInput image={ dataInstance.image }/>
        <div>
          <InputLabel htmlFor='image' required style={{ fontSize: 14 }}>Url de la imagen</InputLabel>
          <TextField id='image' fullWidth margin="dense" type="text" name='image'
            value={dataInstance.image || ""}
            {...register(`image`)} 
            error={errors.image ? true: false}
            onChange={handleChangeData} />
          <ValidateErrorMessage message={errors.image?.message} />
          <IconButton onClick={ () => { handleFieldImageManager('image'); handleOpenImageManager(); } }><ImageIcon /></IconButton>
        </div>
      </div>
      <br />
      <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Descripción</InputLabel>
      <TextField id='name' fullWidth margin="dense" type="text" name='name'
        value={dataInstance.name || ""}
        {...register(`name`)} 
        error={errors.name ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errors.name?.message} />
      <br />
      <InputLabel htmlFor='link' required style={{ fontSize: 14 }}>Link</InputLabel>
      <TextField id='link' fullWidth margin="dense" type="text" name='link'
        value={dataInstance.link || ""}
        {...register(`link`)} 
        error={errors.link ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errors.link?.message} />
    </FlexibleWidthDialog>
    <MediaModal open={imageManagerModalOpen} setOpen={handleCloseImageManager} mediaSelected={handleSelectImageManager} path={`virtual dating`} />
  </>)
}