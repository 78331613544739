import { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { useMutation } from 'react-query'
import { generateProductHash } from "services/api/RealStateDevelopment";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import Highlight from 'react-highlight'
import 'highlight.js/styles/stackoverflow-dark.css'

export default function Code({ open, close, instance }) {
  const [isLoading, setIsLoading] = useState(false)
  const [idCode, setIdCode] = useState("")
  const [isCodeHeadCopied, setIsCodeHeadCopied] = useState(false)
  const [isCodeBodyCopied, setIsCodeBodyCopied] = useState(false)
  useEffect(() => {
    if (open && instance !== undefined && instance !== null) {
      setIsLoading(true)
      mutation.mutate({ id: instance._id })
    }
  }, [open])

  const mutation = useMutation(generateProductHash, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "success") { setIdCode(data.data); }
    }
  })

  const handleClose = () => { close() }

  const handleCopyCode = (type) => {
    let textCode
    switch (type) {
      case "head":
        textCode = '<script src="https://blog.sadasi.com/integraciones/crm/crm-price.js"></script>'
        navigator.clipboard.writeText(textCode)
        setIsCodeHeadCopied(true)
        setTimeout(() => setIsCodeHeadCopied(false), 1000)
        break;
      case "body":
        textCode = `<precio-sadasi hash="${idCode}"></precio-sadasi>`
        navigator.clipboard.writeText(textCode)
        setIsCodeBodyCopied(true)
        setTimeout(() => setIsCodeBodyCopied(false), 1000)
        break;
      default:
        break;
    }
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
    </> : <CircularProgress />}
  </>)

  const CopiedMessage = () => (
    <Typography variant="body2" sx={{ color: "#e76627", textAlign: "center" }}>Copiado</Typography>
  )

  return (<>
    <FlexibleWidthDialog open={open} title={`Código precio desarrollo - ${instance.name}`} actions={<Actions />}>
      <Typography>Insertar este codigo antes del cierre de la etiqueta &#60;/body&#62;</Typography>
      <div className="highlight-code">
        <Highlight >
          {`<script src="https://blog.sadasi.com/integraciones/crm/crm-price.js"></script>`}
        </Highlight>
        <IconButton aria-label="copy-head" onClick={() => handleCopyCode("head")}>
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
      </div>
      {isCodeHeadCopied ? <CopiedMessage /> : null}
      <br />
      <Typography>Insertar este código donde se requiere que se muestre el precio del desarrollo</Typography>
      <div className="highlight-code">
        <Highlight >
          {`<precio-sadasi hash="${idCode}"></precio-sadasi>`}
        </Highlight>
        <IconButton aria-label="copy-price" onClick={() => handleCopyCode("body")}>
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
      </div>
      {isCodeBodyCopied ? <CopiedMessage /> : null}
    </FlexibleWidthDialog>
  </>)
}