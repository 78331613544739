import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getRedirects = () =>
  fetch(
    `${API_BASE_URL}/redirects`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  .then(res => res.json())


export const saveRedirect = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/redirects`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editRedirect = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/redirects/${id}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const deleteRedirect = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/redirects/${id}`,
    {
      method: 'DELETE',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('crmprodtkn'))
    }
  )
  return await res.json()
}