import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputLabel, TextField } from "@mui/material";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { ValidationSchemaHowToGet } from "domain/RealStateDevelopment/services/SchemaValidation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { modulesRQ } from "services/helpers/ReactQuillOptions";

export default function Edit({ open, close, instance, action }) {
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors }, setValue, getValues } = useForm({ 
    resolver: yupResolver(ValidationSchemaHowToGet) 
  })

  useEffect(() => {
    //console.log(instance)
    setDataInstance(instance)
    setValue("from", instance.from)
    setValue("description", instance.description)
  }, [open])

  const handleClose = () => {
    setDataInstance({})
    reset()
    close()
  }

  const handleEdit = (params) => {
    //console.log(params)
    action(params)
    handleClose()
  }

  const handleChangeData = (event) => { setDataInstance({...dataInstance, [event.target.name]: event.target.value}) }

  const handleChangeRichEditor = (value) => { 
    setDataInstance({...dataInstance, description: value})
    setValue('description', value)
  }

  const Actions = () => (<>
    <Button onClick={handleClose}>Cancelar</Button>
    <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
  </> )

  return (<>
    <FlexibleWidthDialog open={open} title="Editar ruta" actions={<Actions />}>
      <InputLabel htmlFor='from' required style={{ fontSize: 14 }}>Desde</InputLabel>
      <TextField id='from' fullWidth margin="dense" type="text" name='from'
        value={dataInstance.from || ""}
        {...register(`from`)} 
        error={errors.from ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errors.from?.message} />
      <br />
      <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Description</InputLabel>
      <div> <ReactQuill value={dataInstance.description || ""} onChange={handleChangeRichEditor} modules={modulesRQ}/> </div>
      <ValidateErrorMessage message={errors.description?.message} />
    </FlexibleWidthDialog>
  </>)
}