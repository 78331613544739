//import firebase from 'firebase/app'
//import 'firebase/storage'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBukvbzsrDPH5XynD1ZEHt_8LkOESJajy4",
  authDomain: "sadasi-integraciones.firebaseapp.com",
  projectId: "sadasi-integraciones",
  storageBucket: "sadasi-integraciones.appspot.com",
  messagingSenderId: "586157116141",
  appId: "1:586157116141:web:84cc3dedfdf0767d3a5ae4"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)

export const storage = getStorage(app)
