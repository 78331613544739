import { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'

import { ValidationSchema } from "../services/SchemaValidation"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import GeneralDataForm from "../modules/GeneralDataForm";
import UbicationContactForm from "../modules/UbicationContactForm";
import CreditsForm from "../modules/CreditsForm";
import { saveRealStateDevelopment } from "services/api/RealStateDevelopment";
import MediaModal from "domain/Media/MediaModal";
import RedirectForm from "../modules/RedirectForm";

export default function Add({ open, close, reportAction, extraData }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  const mutation = useMutation(saveRealStateDevelopment, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleAdd = (params) => { //console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate(params)
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { //console.log("handleChangeDataSelect", event.target.value)
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeDataBoolean = (data) => { setDataInstance({...dataInstance, [data]: !dataInstance[data]}) }

  const handleChangeDataSelect = (prop, value) => { //console.log("handleChangeDataSelect", value)
    if(prop.includes(".")) {
      const separate = prop.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: value } })
      setValue(prop, value)
      return;
    }
    setDataInstance({...dataInstance, [prop]: value})
    setValue(prop, value)
  }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }

  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const handleChangeRichEditor = (field, value) => { //console.log("RichEditor", `${field} - ${value}`)
    if(field.includes(".")) {
      const separate = field.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: value } })
      setValue(field, value)
      return;
    }
    setDataInstance({...dataInstance, [field]: value})
    setValue(field, value)
  }

  const handleChangeJSONEditor = (field, value) => { //console.log("JSONEditor", `${field} - ${value}`)
    setDataInstance({...dataInstance, [field]: value})
    setValue(field, value)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={ open } title={ `Nuevo desarrollo` } actions={ <Actions /> }>
    { (extraData.dRegions !== undefined && extraData.dCities !== undefined && extraData.dKindProducts !== undefined && extraData.dCredits !== undefined) && <>
      <GeneralDataForm 
        dataInstance={ dataInstance }
        register={ register } 
        errors={ errors } 
        handleOnChangeData={ handleOnChangeData } 
        handleChangeDataBoolean={ handleChangeDataBoolean } 
        handleChangeDataSelect={ handleChangeDataSelect } 
        handleOpenImageManager={ handleOpenImageManager } 
        handleFieldImageManager={ handleFieldImageManager } 
        handleChangeRichEditor={ handleChangeRichEditor }
        handleChangeJSONEditor={ handleChangeJSONEditor }
        regionsData={ extraData.dRegions.data }
        citiesData={ extraData.dCities.data } 
        kindProductsData={ extraData.dKindProducts.data } />
      <UbicationContactForm
        dataInstance={ dataInstance } 
        register={ register } 
        errors={ errors }
        handleOnChangeData={ handleOnChangeData }
        handleChangeRichEditor={ handleChangeRichEditor } />
      <CreditsForm 
        dataInstance={ dataInstance }
        register={ register } 
        errors={ errors } 
        handleOnChangeData={ handleOnChangeData } 
        handleChangeDataSelect={ handleChangeDataSelect }
        handleChangeRichEditor={ handleChangeRichEditor } 
        creditsData={ extraData.dCredits.data } />
      <RedirectForm dataInstance={ dataInstance } 
       register={ register } 
       errors={ errors } 
       handleOnChangeData={ handleOnChangeData } 
       handleChangeDataBoolean={ handleChangeDataBoolean } />
    </> }
    </FlexibleWidthDialog>
    <MediaModal open={imageManagerModalOpen} setOpen={handleCloseImageManager} mediaSelected={handleSelectImageManager} path={`real-state-developments/test`} />
  </>)
}