import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputLabel, TextField } from "@mui/material";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { ValidationSchemaVirtualTour } from "domain/Product/services/SchemaValidation";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function Add({ open, close, action }) {
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaVirtualTour) 
  })

  const handleClose = () => {
    setDataInstance({})
    reset()
    close()
  }

  const handleAdd = (params) => {
    //console.log(params)
    action(params)
    handleClose()
  }

  const handleChangeData = (event) => { setDataInstance({...dataInstance, [event.target.name]: event.target.value}) }

  const Actions = () => (<>
    <Button onClick={handleClose}>Cancelar</Button>
    <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
  </> )

  return (<>
    <FlexibleWidthDialog open={open} title="Agregar virtual tour" actions={<Actions />}>
      <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
      <TextField id='description' fullWidth margin="dense" type="text" name='description' multiline maxRows={4}
        value={dataInstance.description || ""}
        {...register(`description`)} 
        error={errors.description ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errors.description?.message} />
      <br />
      <InputLabel htmlFor='link' required style={{ fontSize: 14 }}>Link</InputLabel>
      <TextField id='link' fullWidth margin="dense" type="text" name='link'
        value={dataInstance.link || ""}
        {...register(`link`)} 
        error={errors.link ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errors.link?.message} />
    </FlexibleWidthDialog>
  </>)
}