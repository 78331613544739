import { useContext, useEffect, useState } from "react";
import { Fab, IconButton, Paper, Tooltip } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon, LocationCity as LocationCityIcon, Block as BlockIcon } from '@mui/icons-material'
import { Add as AddIcon } from "@mui/icons-material"
import { useQuery, useQueryClient } from "react-query"

import { AuthContext } from "context/AuthContext";
import { getKindProducts } from "services/api/KindProduct";
import ToolBar from "components/ToolBar";
import { SnackbarGlobal } from "components/Snackbar";
import Add from "./Add";
import Edit from "./Edit";
import SectionIndexAdmin from "components/Loaders/SectionIndexAdmin";
import Remove from "./Remove";

export default function KindProduct() {
  const { setTitlePage } = useContext(AuthContext);
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  //const [openMoreInfo, setOpenMoreInfo] = useState(null)
  const [instanceSelected, setInstanceSelected] = useState({})
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const [dataFilter, setDataFilter] = useState(null);
  const queryClient = useQueryClient()
  const { isLoading, isFetching, error, data } = useQuery(
    'kindProductsList',
    getKindProducts,
    { refetchOnWindowFocus: false }
  )

  useEffect(() => { 
    setTitlePage('Tipos de Producto') 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data !== undefined && data !== null && data !== "") setDataFilter(data.data)
  }, [data])

  const handleRefreshData = () => { queryClient.invalidateQueries('kindProductsList') }

  const handleResetActions = () => { setOpenAction(0) }

  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    if (success) { handleResetActions(); handleRefreshData() }
  }

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const Actions = ({ item }) => (<>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenAction(2) }}><EditIcon color="info" /></IconButton>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenAction(3) }}><DeleteIcon color="error" /></IconButton>
  </>)

  if (isLoading || isFetching) return <SectionIndexAdmin />
  if (error) return <div> Ha ocurrido un error </div>

  return (<>
    <div className="toolbar_container"> <ToolBar searchFunction={handleSearch} /> </div>
    <div>
      {(dataFilter !== null && dataFilter.length === 0) && <Paper elevation={1} className="without-data-container">
        <p> No hay datos a mostrar </p>
        <p> Agrega tu primer registro dando click en el botón <Fab color="primary" size="small" disabled><AddIcon /></Fab> del lado derecho inferior</p>
      </Paper>}
      {(dataFilter !== null) && dataFilter.map((item, index) =>
        <Paper className="list_item_container" elevation={1} key={index}>
          <div className="list_item_data">
            <div> <LocationCityIcon /> </div>
            <div>
              <div style={{ color: item.active ? "#000000" : "#a7a4a4" }}>
                <div style={{ display: "flex" }}>
                  <span style={{ marginRight: 8 }}>{item.name}</span> {!item.active ? <BlockIcon /> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="list_item_actions"> <Actions item={item} /> </div>
        </Paper>
      )}
    </div>
    <span className="fab_add">
      <Tooltip title="Agregar"><Fab color="primary" onClick={() => setOpenAction(1)}><AddIcon /></Fab></Tooltip>
    </span>
    <Add open={openAction === 1} close={() => handleResetActions("add")} reportAction={handleWatchAction} />
    <Edit open={openAction === 2} close={() => handleResetActions("edit")} instance={instanceSelected} reportAction={handleWatchAction} />
    <Remove open={openAction === 3} close={() => handleResetActions()} instance={instanceSelected} reportAction={handleWatchAction} />
    <SnackbarGlobal vertical='bottom' horizontal='center' open={notification.open} close={() => setNotification({ open: false, message: "", severity: "" })} message={notification.message} severity={notification.severity} />
  </>)
}