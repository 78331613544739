import { Autocomplete, IconButton, InputLabel, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { Image as ImageIcon } from "@mui/icons-material";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { modulesRQ } from "services/helpers/ReactQuillOptions";

export default function GeneralDataForm({ dataInstance, realStateDevelopment, formAttributes, changeDataInstance, changeDataBooleanInstance, handleChangeRichEditor, imageManager, extraData }) {
  return ( <>
    <Typography variant="inherit" component="h4"> Datos Generales </Typography>
    <br />
    <InputLabel htmlFor='slug' required style={{ fontSize: 14 }}>Slug</InputLabel>
    <TextField id='slug' fullWidth margin="dense" type="text" name='slug'
      value={dataInstance.slug || ""} 
      {...formAttributes.register(`slug`)} error={formAttributes.errors.slug ? true: false} 
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.slug?.message} />
    <br />
    <InputLabel htmlFor='real_state_development' required style={{ fontSize: 14 }}>Desarrollo</InputLabel>
    <TextField id='real_state_development' fullWidth margin="dense" type="text" name='real_state_development'
      value={realStateDevelopment} 
      {...formAttributes.register(`real_state_development`)} error={formAttributes.errors.real_state_development ? true: false} 
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.real_state_development?.message} />
    <br />
    <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
    <TextField id='name' fullWidth margin="dense" type="text" name='name'
      value={dataInstance.name || ""} 
      {...formAttributes.register(`name`)} error={formAttributes.errors.name ? true: false}
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.name?.message} />
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <div> <ReactQuill value={dataInstance.description || ""} modules={modulesRQ} onChange={(value) => { handleChangeRichEditor('description', value) }}/> </div>
    {/* <TextField id='description' fullWidth margin="dense" type="text" name='description' multiline maxRows={5}
      value={dataInstance.description || ""} 
      {...formAttributes.register(`description`)} error={formAttributes.errors.description ? true: false}
      onChange={changeDataInstance} /> */}
    <ValidateErrorMessage message={formAttributes.errors.description?.message} />
    <br />
    <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
      <div style={{ textAlign: "center" }}>
        <img src={dataInstance.facade || ""} style={{ width: "90%" }}/>
      </div>
      <div>
        <InputLabel htmlFor='facade' required style={{ fontSize: 14 }}>Fachada</InputLabel>
        <TextField id='facade' fullWidth margin="dense" type="text" name='facade'
          value={dataInstance.facade || ""} 
          {...formAttributes.register(`facade`)} error={formAttributes.errors.facade ? true: false}
          onChange={changeDataInstance} />
        <ValidateErrorMessage message={formAttributes.errors.facade?.message} />
        <IconButton onClick={ () => { imageManager.setField('facade'); imageManager.open(); } }><ImageIcon /></IconButton>
      </div>
    </div>
    <br />
    <InputLabel htmlFor='square_meters_of_land' required style={{ fontSize: 14 }}>Metros cuadrados de terreno </InputLabel>
    <TextField id='square_meters_of_land' fullWidth margin="dense" type="number" name='square_meters_of_land'
      value={dataInstance.square_meters_of_land || ""} 
      {...formAttributes.register(`square_meters_of_land`)} error={formAttributes.errors.square_meters_of_land ? true: false} 
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.square_meters_of_land?.message} />
    <br />
    <InputLabel htmlFor='square_meters_of_construction' required style={{ fontSize: 14 }}>Metros cuadrados de construcción </InputLabel>
    <TextField id='square_meters_of_construction' fullWidth margin="dense" type="number" name='square_meters_of_construction'
      value={dataInstance.square_meters_of_construction || ""} 
      {...formAttributes.register(`square_meters_of_construction`)} error={formAttributes.errors.square_meters_of_construction ? true: false} 
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.square_meters_of_construction?.message} />
    <br />
    <InputLabel htmlFor='price' required style={{ fontSize: 14 }}>Precio </InputLabel>
    <TextField id='price' fullWidth margin="dense" type="number" name='price'
      value={dataInstance.price || ""} 
      {...formAttributes.register(`price`)} error={formAttributes.errors.price ? true: false} 
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.price?.message} />
    <br />
    <InputLabel htmlFor='text_before_price' style={{ fontSize: 14 }}>Texto antes del precio</InputLabel>
    <TextField id='text_before_price' fullWidth margin="dense" type="text" name='text_before_price' multiline maxRows={10}
      value={dataInstance.text_before_price || ""} 
      {...formAttributes.register(`text_before_price`)} error={formAttributes.errors.text_before_price ? true: false}
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.text_before_price?.message} />
    <br />
    <InputLabel htmlFor='text_after_price' style={{ fontSize: 14 }}>Texto después del precio</InputLabel>
    <TextField id='text_after_price' fullWidth margin="dense" type="text" name='text_after_price' multiline maxRows={10}
      value={dataInstance.text_after_price || ""} 
      {...formAttributes.register(`text_after_price`)} error={formAttributes.errors.text_after_price ? true: false}
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.text_after_price?.message} />
    <br />



    <InputLabel htmlFor='discount' style={{ fontSize: 14 }}>Descuento </InputLabel>
    <TextField id='discount' fullWidth margin="dense" type="number" name='discount'
      value={dataInstance.discount || 0} 
      {...formAttributes.register(`discount`)} error={formAttributes.errors.discount ? true: false} 
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.discount?.message} />
    <br />
    <InputLabel htmlFor='income_from' style={{ fontSize: 14 }}>Ingresos desde </InputLabel>
    <TextField id='income_from' fullWidth margin="dense" type="number" name='income_from'
      value={dataInstance.income_from || 0} 
      {...formAttributes.register(`income_from`)} error={formAttributes.errors.income_from ? true: false} 
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.income_from?.message} />
    <br />
    <InputLabel htmlFor='payments_from' style={{ fontSize: 14 }}>Pagos mensuales desde </InputLabel>
    <TextField id='payments_from' fullWidth margin="dense" type="number" name='payments_from'
      value={dataInstance.payments_from || 0} 
      {...formAttributes.register(`payments_from`)} error={formAttributes.errors.payments_from ? true: false} 
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.payments_from?.message} />
    <br />
    <InputLabel htmlFor='kind_product' required style={{ fontSize: 14 }}>Tipo de Producto</InputLabel>
    <TextField id="kind_product" fullWidth margin="dense" name='kind_product' select
      value={dataInstance.kind_product || []}
      {...formAttributes.register(`kind_product`)} error={formAttributes.errors.kind_product ? true: false}
      onChange={changeDataInstance} >
      {extraData.kindProductsData.map((kind_product) => ( <MenuItem key={kind_product._id} value={kind_product._id}> { kind_product.name } </MenuItem> ))}
    </TextField>
    <ValidateErrorMessage message={formAttributes.errors.kind_product?.message} />
    <br />
    <InputLabel htmlFor='is_coming_soon' required style={{ fontSize: 14 }}>Próximamente?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-is_coming_soon` }}
      onClick={ () => changeDataBooleanInstance('is_coming_soon') }
      checked={dataInstance.is_coming_soon || false} 
      {...formAttributes.register(`is_coming_soon`)} 
    />
    <br />
    <InputLabel htmlFor='is_available' required style={{ fontSize: 14 }}>Está disponible?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-is_available` }}
      onClick={ () => changeDataBooleanInstance('is_available') }
      checked={dataInstance.is_available || false} 
      {...formAttributes.register(`is_available`)}
    />
    <br />
    <InputLabel htmlFor='brochure' style={{ fontSize: 14 }}>Brochure</InputLabel>
    <TextField id='brochure' fullWidth margin="dense" type="text" name='brochure'
      value={dataInstance.brochure || ""} 
      {...formAttributes.register(`brochure`)} error={formAttributes.errors.brochure ? true: false}
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.brochure?.message} />
    <br />
    <InputLabel htmlFor='structured_data' style={{ fontSize: 14 }}>Datos estructurados</InputLabel>
    <TextField id='structured_data' fullWidth margin="dense" type="text" name='structured_data' multiline maxRows={10}
      value={dataInstance.structured_data || ""} 
      {...formAttributes.register(`structured_data`)} error={formAttributes.errors.structured_data ? true: false}
      onChange={changeDataInstance} />
    <ValidateErrorMessage message={formAttributes.errors.structured_data?.message} />
    <br />
    <InputLabel htmlFor='activeInAmigoSadasi' required style={{ fontSize: 14 }}>Activa en Amigo Sadasi?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInAmigoSadasi` }}
      onClick={() => changeDataBooleanInstance('activeInAmigoSadasi')}
      checked={dataInstance.activeInAmigoSadasi || false} 
      {...formAttributes.register(`activeInAmigoSadasi`)} 
    />
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Activo?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
      onClick={ () => changeDataBooleanInstance('active') }
      checked={dataInstance.active || false} 
      {...formAttributes.register(`active`)} 
    />
    <br />
  </> )
}