import { Skeleton } from "@mui/material";

export default function SectionIndexAdmin() {
  return ( <>
    <div className="toolbar_container"><Skeleton variant="rectangular" width="100%" height={50} /></div>
    <div className="section-index-admin">
      { [...Array(4).keys()].map((item, index) => 
        <div key={index}>
          <Skeleton variant="rectangular" width="100%" height={90} />
        </div>
      ) }
    </div>
  </>);
}