import { Box, Grid, Skeleton } from "@mui/material";

export default function LoaderRSDOptions() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}> <Skeleton width="100%" height={118} /> </Grid>
      {Array.from(new Array(8)).map((item, index) => (
        <Grid key={index} item xs={6} sm={6} md={4} lg={3} style={{ textAlign: "center" }}>
          <Skeleton variant="rectangular" width="100%" height={100} />  
          <Skeleton width="100%" />
        </Grid>
      ))}
    </Grid>
  );
}