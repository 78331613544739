import { Autocomplete, InputLabel, TextField, Typography } from "@mui/material";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { modulesRQ } from "services/helpers/ReactQuillOptions";

export default function CreditsForm({ dataInstance, register, errors, handleOnChangeData, handleChangeDataSelect, handleChangeRichEditor, creditsData }) {
  return ( <>
    <Typography variant="inherit" component="h4"> Créditos </Typography>
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <div> <ReactQuill value={dataInstance.credits?.description || ""} modules={modulesRQ} onChange={(value) => { handleChangeRichEditor('credits.description', value) }}/> </div>
    <ValidateErrorMessage message={errors.credits?.description?.message} />
    <br />
    <InputLabel htmlFor='credits_list' style={{ fontSize: 14 }}>Selecciona los créditos disponibles para el desarrollo</InputLabel><br />
    <Autocomplete multiple id="credits_list"
      options={ creditsData }
      getOptionLabel={ (option) => option.name }
      defaultValue={ dataInstance.credits?.credits_list ? creditsData.filter(value => dataInstance.credits?.credits_list.includes(value._id)) : [] }
      onChange={ (event, newValue) => { handleChangeDataSelect("credits.credits_list", newValue.map(value => value._id)) } }
      renderInput={(params) => ( <TextField {...params} variant="outlined" placeholder="Crédios"/> )} />
    <ValidateErrorMessage message={errors.credits?.credits_list?.message} />
    <br />
  </> )
}