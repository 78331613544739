import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'

import { ValidationSchema } from "../services/SchemaValidation"
import { editProduct } from "services/api/Product";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
//import DialogImageManager from "domain/ImageManager/DialogImageManager";
import MediaModal from "domain/Media/MediaModal";
import GeneralDataForm from "../modules/GeneralDataForm";
import RedirectForm from "../modules/RedirectForm";

export default function Edit({ open, close, reportAction, instance, realStateDevelopment, extraData }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  /* Carga de datos inicial cuando se abre el dialogo */
  useEffect(() => { 
    if (open) { 
      const keys = ["slug","real_state_development","name","description","facade","square_meters_of_land","square_meters_of_construction","price","kind_product","is_coming_soon","is_available","brochure","structured_data","active"]
      for (let index = 0; index < keys.length; index++) { setValue(keys[index], instance[keys[index]]) }
      setDataInstance(instance)
    }
  }, [open])


  const mutation = useMutation(editProduct, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('edit', false, { message: 'Error al guardar los datos'}, false)
      } else { reportAction('edit', false, { }, true) }
    }
  })

  const handleEdit = (params) => { //console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: params})
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { //console.log("handleChangeDataSelect", event.target.value)
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeDataBoolean = (data) => { setDataInstance({...dataInstance, [data]: !dataInstance[data]}) }

  /*const handleChangeDataSelect = (prop, value) => { //console.log("handleChangeDataSelect", value)
    if(prop.includes(".")) {
      const separate = prop.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: value } })
      setValue(prop, value)
      return;
    }
    setDataInstance({...dataInstance, [prop]: value})
    setValue(prop, value)
  }*/

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }

  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const handleChangeRichEditor = (field, value) => { //console.log("RichEditor", `${field} - ${value}`)
    if(field.includes(".")) {
      const separate = field.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: value } })
      setValue(field, value)
      return;
    }
    setDataInstance({...dataInstance, [field]: value})
    setValue(field, value)
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={ open } title={ `Editar producto - ${instance.name}` } actions={ <Actions /> }>
    { (extraData.dKindProducts !== undefined) && <>
      <GeneralDataForm 
        dataInstance={ dataInstance }
        realStateDevelopment={ realStateDevelopment }
        formAttributes={ { register: register, errors: errors } }
        changeDataInstance={ handleOnChangeData } 
        changeDataBooleanInstance={ handleChangeDataBoolean } 
        handleChangeRichEditor={ handleChangeRichEditor } 
        //changeDataSelectInstance={ handleChangeDataSelect }
        imageManager={ { setField: handleFieldImageManager, open: handleOpenImageManager  } }
        extraData={ { kindProductsData: extraData.dKindProducts.data } } />
      <RedirectForm dataInstance={ dataInstance } 
       register={ register } 
       errors={ errors } 
       handleOnChangeData={ handleOnChangeData } 
       handleChangeDataBoolean={ handleChangeDataBoolean } />
    </> }
    </FlexibleWidthDialog>
    {/* <DialogImageManager open={ imageManagerModalOpen } 
      actionClose={ handleCloseImageManager } 
      setImageSelected={ handleSelectImageManager } /> */}
    <MediaModal open={imageManagerModalOpen} setOpen={handleCloseImageManager} mediaSelected={handleSelectImageManager} path={`products//${instance._id}`} />
  </>)
}