import { Button,  InputLabel, TextField, Typography } from "@mui/material";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";

export default function OptionForm({ mode, dataInstance, changeDataInstance, formAttributes, actions}) {
  
  const handleChangeData = (field, value) => { changeDataInstance("option", field, value) }

  return ( <>
    <Typography variant="h6" component="h6"> { mode ? "Agregar opcion" : "Editar opcion" } </Typography><br />
    
    <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
    <TextField id='name' fullWidth margin="dense" type="text" name='name'
      value={dataInstance.name || ""}
      {...formAttributes.register(`name`)} 
      error={formAttributes.errors.name ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.name?.message} />
    <br />
    <InputLabel htmlFor='link' required style={{ fontSize: 14 }}>Link</InputLabel>
    <TextField id='link' fullWidth margin="dense" type="text" name='link'
      value={dataInstance.link || ""}
      {...formAttributes.register(`link`)} 
      error={formAttributes.errors.link ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.link?.message} />
    <br />
    
    <div style={{ textAlign: "right" }}>
      <Button onClick={actions.cancelAction} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
      { (!mode) ? <Button onClick={actions.editAction} variant="contained" color="secondary"> Actualizar </Button>
      : <Button onClick={formAttributes.submitValidation(actions.saveAction)} variant="contained" color="primary"> Agregar </Button> }
    </div>
  </> )
}