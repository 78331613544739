import { useEffect, useState } from "react";
import { CircularProgress, Grid, IconButton,Typography } from "@mui/material";
import { Save as SaveIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon, Add as AddIcon } from "@mui/icons-material";
import { useMutation } from "react-query";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ValidationSchemaArrayHowToGet } from "../services/SchemaValidation";
import FullScreenDialog from "components/Dialog/FullScreenDialog";
import ImageGalleryCard from "components/Card/ImageLeftCard";
import { editRealStateDevelopmentHowToGet } from "services/api/RealStateDevelopment";
import Add from "./Add";
import Edit from "./Edit";
import Remove from "./Remove";

export default function ImagesGallery({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  const [position, setPosition] = useState(null)
  const [instanceSelected, setInstanceSelected] = useState({})
    let { /*handleSubmit: hTBP, formState: { errors: eTBP },*/ control: cTBP, setValue: svTBP, getValues } = useForm({ 
    resolver: yupResolver(ValidationSchemaArrayHowToGet), 
    defaultValues: { 'how_to_get': [] } 
  })
  const {fields: fTBP, append: aTBP, remove: rmvTBP, update: updTBP, move: mvTBP} = useFieldArray({ 
    name: 'how_to_get', control: cTBP 
  })

  useEffect(() => { if (open) { svTBP('how_to_get', instance.how_to_get) } }, [open])

  const mutation = useMutation(editRealStateDevelopmentHowToGet, {
    onSuccess: (data) => {
      setIsLoading(false)
      /* if(data.status === "error") { 
        reportAction('how-to-get', false, { message: 'Error al editar los datos'}, false)
      } else { 
        reportAction('how-to-get', false, { }, true) 
      } */
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const onSubmit = () => { //console.log("onSubmit", fTBP) 
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: fTBP })
  }

  const handleClose = () => { close(); }

  const handleAddHTG = (data) => { aTBP(data) }
  const handleLoadEditHTG = (position, data) => {
    setPosition(position)
    setInstanceSelected(data)
  }
  const handleEditHTG = (data) => { 
    let dataTemp = { ...fTBP[position], from: data.from, description: data.description }
    updTBP(position, dataTemp) 
  }
  const handleLoadRemoveHTG = (position, data) => {
    setPosition(position)
    setInstanceSelected(data)
  }
  const handleRemoveHTG = () => { rmvTBP(position) }
  const handlerChangePositionHTG = (direction, position) => { mvTBP(position, direction === 'up' ? position - 1 : position + 1) }

  const Actions = ({ item, index, totalItems }) => ( <>
    <IconButton aria-label="edit" onClick={ () => { handleLoadEditHTG(index, item); setOpenAction(2) }  }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => { handleLoadRemoveHTG(index, item); setOpenAction(3) } }> <DeleteIcon color='error'/> </IconButton>
    <div>
    {index !== 0 && <IconButton aria-label="up-position" onClick={ () => handlerChangePositionHTG("up", index) }> <ArrowUpwardIcon /> </IconButton>}
    {index !== totalItems - 1 && <IconButton aria-label="down-position" onClick={ () => handlerChangePositionHTG("down", index) }> <ArrowDownwardIcon /> </IconButton> }
    </div>
  </> )

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = getValues("how_to_get");
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    svTBP('how_to_get', updatedList)
  }
 
  return ( <>
    <FullScreenDialog open={ open } changeOpen={ handleClose } title={`Cómo llegar - ${instance.name}`} actions={ <>
      { !isLoading ? <>
        <IconButton autoFocus color="inherit" onClick={ () => setOpenAction(1) } aria-label="add"> <AddIcon /> </IconButton> 
        <IconButton autoFocus color="inherit" onClick={ onSubmit } aria-label="save"> <SaveIcon /> </IconButton>
      </> : <CircularProgress /> }
    </> }>
      <div className="admin-image-gallery one-columns">
        <div className="list-container">
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="droppable">
              { (provided) => (
                <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                { (fTBP.length > 0) ? (
                fTBP.map((item, i) => (
                  <Draggable key={item.id} draggableId={item.id} index={i}>
                    {(provided) => (
                      <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                        <ImageGalleryCard 
                          image={item.image} 
                          description={item.from}
                          actions={ 
                            <Actions item={ item } index={ i } totalItems={ fTBP.length }/> 
                          }
                        />
                      </Grid>
                    )}
                  </Draggable>
                )) ) : <Grid item xs={12}> <Typography variant="inherit">No hay rutas agregadas</Typography> </Grid> }
                </Grid>
              ) }
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </FullScreenDialog>
    <Add open={ openAction === 1 } close={ () => setOpenAction(0) } action={ handleAddHTG } />
    <Edit open={ openAction === 2 } close={ () => setOpenAction(0) } instance={ instanceSelected } action={ handleEditHTG } />
    <Remove open={ openAction === 3 } close={ () => setOpenAction(0) } instance={ instanceSelected } action={ handleRemoveHTG } />
  </> )
}