import { useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import ImageInput from "components/Image/ImageInput";

export default function Remove({ open, close, instance, action }) {
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => { close() }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="error" onClick={ () => { action(); handleClose() } }>Eliminar</Button>
      </> : <CircularProgress /> }
  </>)

  return (
    <FlexibleWidthDialog open={open} title="Eliminar" actions={<Actions />}>
      <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
        <ImageInput image={ instance.image }/>
        <Typography variant="body1" component="p">
          ¿Estas seguro de querer eliminar <b>{instance.name}</b>?
        </Typography>
      </div>
    </FlexibleWidthDialog>
  )
}