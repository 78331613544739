import { Avatar, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import { Block as BlockIcon } from '@mui/icons-material'

export default function RSDlist({ _id, name, logo, city, active, onClick, isFocus }) {
  return (
    <>
      <ListItem alignItems="flex-start" disablePadding>
        <ListItemButton onClick={() => onClick()} selected={isFocus} 
          sx={[ 
            {'&.Mui-selected': { backgroundColor: "rgb(231 102 39 / 30%)" } }, 
            {'&.Mui-selected:hover': { backgroundColor: "rgb(231 102 39 / 30%)" } }
          ]}>
          <ListItemAvatar>
            {active ? <Avatar variant="square" alt={name} src={logo} style={{ height: "auto" }}/>
            : <Avatar><BlockIcon fontSize="small"/></Avatar> }
          </ListItemAvatar>
          <ListItemText sx={{ color: active ? "#000000" : "#a7a4a4" }}
            primary={name}
            secondary={city.name}
          />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  )
}
