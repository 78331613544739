import React, { useContext } from "react";
import { Toolbar, IconButton, AppBar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Menu as MenuIcon } from "@mui/icons-material";
import { AuthContext } from "context/AuthContext";

const drawerWidth = 251;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px) !important`,
      marginLeft: drawerWidth,
    }
  },
  menuButton: {
    marginLeft: `${theme.spacing(0.5)} !important`,
    marginRight: `${theme.spacing(2)} !important`,
    [theme.breakpoints.up('md')]: {
      display: 'none !important',
    },
  },
}))

const Header = ({ actionMenu }) => {
  const classes = useStyles()
  const { titlePage } = useContext(AuthContext)

  return (
    <AppBar position="fixed" className={classes.appBar} style={{backgroundColor: "#FFDE73", color: "#000000"}}>
      <Toolbar>
        <IconButton 
          color="inherit"
          aria-label="open drawer"
          edge="start" 
          onClick={actionMenu}
          className={classes.menuButton} 
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>{ titlePage }</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
