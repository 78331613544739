import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  slug: Yup.string().required("Campo requerido"),
  name: Yup.string().required("Campo requerido"),
  logo: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido"),
  region: Yup.string().required("Campo requerido"),
  city: Yup.string().required("Campo requerido"),
  show_in_random_image_home: Yup.boolean().required("Campo requerido"),
  random_image_home: Yup.string().when('show_in_random_image_home', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  show_in_featured_image_home: Yup.boolean().required("Campo requerido"),
  featured_image_home: Yup.string().when('show_in_featured_image_home', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  kind_products: Yup.array().of(Yup.string()),
  ubication: Yup.object().shape({
    address: Yup.string().required("Campo requerido"),
    google_maps: Yup.string().required("Campo requerido"),
    latitude: Yup.number().typeError("Campo requerido").required("Campo requerido"),
    longitude: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  }),
  contact: Yup.object().shape({
    email: Yup.string().required("Campo requerido"),
    phone: Yup.string().required("Campo requerido"),
    whatsapp: Yup.string().nullable(),
  }),
  credits: Yup.object().shape({
    description: Yup.string().required("Campo requerido"),
    credits_list: Yup.array().of(Yup.string())
  }),
  brochure: Yup.string().nullable(),
  structured_data: Yup.string().nullable(),
  isRedirectActive: Yup.boolean().required("Campo requerido"),
  isRedirectToCity: Yup.boolean().when('isRedirectActive', {
    is: true,
    then: Yup.boolean().required("Campo requerido"),
    otherwise: Yup.boolean()
  }),
  redirectCode: Yup.string().when('isRedirectActive', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  redirectUrl: Yup.string().when(['isRedirectActive', 'isRedirectToCity'], {
    is: (isRedirectActive, isRedirectToCity) => isRedirectActive && !isRedirectToCity,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  active: Yup.boolean().required("Campo requerido"),
})

// Image gallery validation
export const ValidationSchemaImageGallery = Yup.object().shape({
  image: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido")
})

export const ValidationSchemaArrayImageGallery = Yup.object({
  image_gallery: Yup.array().of(
    Yup.object().shape({
      image: Yup.string().required("Campo requerido"),
      description: Yup.string().required("Campo requerido")
    })
  )
})

// Amenitities validation
export const ValidationSchemaAmenity = Yup.object().shape({
  icon: Yup.string().required("Campo requerido"),
  name: Yup.string().required("Campo requerido")
})

export const ValidationSchemaAmenities = Yup.object({
  description: Yup.string().required("Campo requerido"),
  amenities_list: Yup.array().of(
    Yup.object().shape({
      icon: Yup.string().required("Campo requerido"),
      name: Yup.string().required("Campo requerido")
    })
  )
})

// Virtual Appointment validation
export const ValidationSchemaVirtualAppointment = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  image: Yup.string().required("Campo requerido"),
  link: Yup.string().required("Campo requerido")
})

export const ValidationSchemaArrayVirtualAppointment = Yup.object({
  virtual_appointment: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Campo requerido"),
      image: Yup.string().required("Campo requerido"),
      link: Yup.string().required("Campo requerido")
    })
  )
})

// How To Get validation
export const ValidationSchemaHowToGet = Yup.object().shape({
  from: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido")
})

export const ValidationSchemaArrayHowToGet = Yup.object({
  how_to_get: Yup.array().of(
    Yup.object().shape({
      from: Yup.string().required("Campo requerido"),
      description: Yup.string().required("Campo requerido")
    })
  )
})

// How To Get validation
export const ValidationSchemaArraySalesupTokens = Yup.object({
  salesup_tokens: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Campo requerido"),
      value: Yup.string().required("Campo requerido")
    })
  )
})

// CRM Properties validation
export const ValidationSchemaCRMProperties = Yup.object().shape({
  medio: Yup.string().required("Campo requerido"),
  tags: Yup.string().required("Campo requerido")
})

// Chatbot
export const ValidationSchemaChatbot = Yup.object().shape({
  active: Yup.boolean().required("Campo requerido"),
  title: Yup.string().required("Campo requerido"),
  color_button_titlebar_color: Yup.string().required("Campo requerido"),
  color_button_titlebar_font_color: Yup.string().required("Campo requerido"),
  color_chat_background_color: Yup.string().required("Campo requerido"),
  color_font_color: Yup.string().required("Campo requerido"),
  color_bot_message: Yup.string().required("Campo requerido"),
  color_user_message: Yup.string().required("Campo requerido"),
  color_send_icon: Yup.string().required("Campo requerido"),
})

// Data Structured
export const ValidationSchemaDataStructured = Yup.object().shape({
  context: Yup.string().required("Campo requerido"),
  type: Yup.string().required("Campo requerido"),
  image: Yup.array().of(Yup.string().required("Campo requerido")),
  logo: Yup.string().required("Campo requerido"),
  name: Yup.string().required("Campo requerido"),
  address: Yup.object().shape({
    type: Yup.string().required("Campo requerido"),
    streetAddress: Yup.string().required("Campo requerido"),
    addressLocality: Yup.string().required("Campo requerido"),
    addressRegion: Yup.string().required("Campo requerido"),
    postalCode: Yup.string().required("Campo requerido"),
    addressCountry: Yup.string().required("Campo requerido")
  }),
  geo: Yup.object().shape({
    type: Yup.string().required("Campo requerido"),
    latitude: Yup.number().required("Campo requerido"),
    longitude: Yup.number().required("Campo requerido")
  }),
  url: Yup.string().required("Campo requerido"),
  telephone: Yup.string().required("Campo requerido"),
  email: Yup.string().required("Campo requerido"),
  priceRange: Yup.string().required("Campo requerido"),
  openingHoursSpecification: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Campo requerido"),
      dayOfWeek: Yup.array().of(Yup.string().required("Campo requerido")),
      opens: Yup.string().required("Campo requerido"),
      closes: Yup.string().required("Campo requerido")
    })
  )
})

export const ValidationSchemaAlttaHomes = Yup.object().shape({
  description: Yup.string().required("Campo requerido"),
  show_in_random_image_home: Yup.boolean().required("Campo requerido"),
  random_image_home: Yup.string().when('show_in_random_image_home', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  show_in_featured_image_home: Yup.boolean().required("Campo requerido"),
  amenities_description: Yup.string().required("Campo requerido"),
  credits_description: Yup.string().required("Campo requerido"),
})

export const ValidationSchemaHeroes = Yup.object().shape({
  description: Yup.string().required("Campo requerido"),
  show_in_random_image_home: Yup.boolean().required("Campo requerido"),
  random_image_home: Yup.string().when('show_in_random_image_home', {
    is: true,
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  show_in_featured_image_home: Yup.boolean().required("Campo requerido"),
  amenities_description: Yup.string().required("Campo requerido"),
  credits_description: Yup.string().required("Campo requerido"),
})