
export const modulesRQ = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{'color':['#e76627','#f8d34b']}],
    [{'size': []}],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],    [{ 'align': [] }],
    ['link'],
    [{ 'script': 'sub'}, { 'script': 'super' }],
  ],
  history: {
    'delay': 2500,
    'userOnly': true
  },
  clipboard: {
    matchVisual: false
  }
}

export const stylesEditingAreaQR = {
  fontSize: 16
}