import { InputLabel, Switch, TextField, Typography } from "@mui/material";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";

export default function RedirectForm({ dataInstance, register, errors, handleOnChangeData, handleChangeDataBoolean }) {
  return ( <>
    <Typography variant="inherit" component="h4"> Redirección </Typography>
    <br />
    <InputLabel htmlFor='isRedirectActive' required style={{ fontSize: 14 }}>¿La redirección está activa?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-isRedirectActive` }}
      onClick={ () => handleChangeDataBoolean('isRedirectActive') }
      checked={(dataInstance.isRedirectActive) || false} 
      {...register(`isRedirectActive`)} 
    />
    <br />

    { (dataInstance.isRedirectActive !== undefined && dataInstance.isRedirectActive) && <div>
    <InputLabel htmlFor='isRedirectToRealState' required style={{ fontSize: 14 }}>¿La redirección es al desarrollo?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-isRedirectToRealState` }}
      onClick={ () => handleChangeDataBoolean('isRedirectToRealState') }
      checked={dataInstance.isRedirectToRealState ? dataInstance.isRedirectToRealState : false} 
      {...register(`isRedirectToRealState`)} 
    />
    <br />
    <InputLabel htmlFor='redirectCode' required style={{ fontSize: 14 }}>Code</InputLabel>
    <TextField id='redirectCode' fullWidth margin="dense" type="text" name='redirectCode'
      value={dataInstance.redirectCode || ""} 
      {...register(`redirectCode`)} error={errors.redirectCode ? true: false} 
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.redirectCode?.message} />
    <br />
    { (dataInstance.isRedirectToRealState !== undefined && !dataInstance.isRedirectToRealState) && <div>
    <InputLabel htmlFor='redirectUrl' required style={{ fontSize: 14 }}>Url a la que se redirecciona</InputLabel>
    <TextField id='redirectUrl' fullWidth margin="dense" type="text" name='redirectUrl'
      value={dataInstance.redirectUrl || ""} 
      {...register(`redirectUrl`)} error={errors.redirectUrl ? true: false} 
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.redirectUrl?.message} />
    <br />
    </div> }
    </div> }

  </> )
}