import { useEffect, useState } from "react";
import { Button, CircularProgress, InputLabel, MenuItem, Switch, TextField } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'

import { ValidationSchema } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { editRedirect } from "../../../services/api/Redirect";

export default function Edit({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  useEffect(() => {
    if (open ) {
      setDataInstance(instance)
    }
  }, [open])

  const mutation = useMutation(editRedirect, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleAdd = (params) => { 
    //console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: params })
  }
  
  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => { 
    setDataInstance({...dataInstance, [event.target.name]: event.target.value}) 
  }

  const handleChangeActive = () => {
    setDataInstance({...dataInstance, active: !dataInstance.active}) 
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Editar redirección`} actions={<Actions />}>
      <>
      <InputLabel htmlFor='url' required style={{ fontSize: 14 }}>Url original</InputLabel>
        <TextField id='url' fullWidth margin="dense" type="text" name='url' size="small"
          value={dataInstance.url || ""}
          {...register(`url`)} 
          error={errors.url ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.url?.message} />
        <br />
        <InputLabel htmlFor='type' required style={{ fontSize: 14 }}>Condition</InputLabel>
        <TextField id="type" fullWidth margin="dense" name='type' select size="small"
          value={dataInstance.type || []}
          {...register(`type`)} error={errors.type ? true: false}
          onChange={handleOnChangeData} >
            <MenuItem value={1}> Ruta idéntica </MenuItem>
            <MenuItem value={2}> Que contenga </MenuItem>
            <MenuItem value={3}> Regex </MenuItem>
        </TextField>
        <ValidateErrorMessage message={errors.type?.message} />
        <br />
        <InputLabel htmlFor='value' style={{ fontSize: 14 }}>Valor</InputLabel>
        <TextField id='value' fullWidth margin="dense" type="text" name='value' size="small"
          value={dataInstance.value || ""}
          {...register(`value`)} 
          error={errors.value ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.value?.message} />
        <br />
        <InputLabel htmlFor='redirect' required style={{ fontSize: 14 }}>Url a redireccionar</InputLabel>
        <TextField id='redirect' fullWidth margin="dense" type="text" name='redirect' size="small"
          redirect={dataInstance.redirect || ""}
          {...register(`redirect`)} 
          error={errors.redirect ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.redirect?.message} />
        <br />
        <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Activa?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={handleChangeActive}
          checked={dataInstance.active || false} 
          {...register(`active`)} 
        />
      </>
    </FlexibleWidthDialog>
  </>)
}