import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { editProductOrder } from "services/api/Product";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import ImageGalleryCard from "components/Card/ImageLeftCard";

export default function Order({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  let { reset, /*handleSubmit, register, formState: { errors },*/ control, setValue, getValues  }  = useForm({ 
    defaultValues: { 'products': [] }
  })
  const {fields: fTBP/* , append: aTBP, remove: rmvTBP, update: updTBP, move: mvTBP */} = useFieldArray({ 
    name: 'products', control: control 
  })

  useEffect(() => { if (open) { setValue('products', instance) } }, [open])

  const mutation = useMutation(editProductOrder, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('order', false, { message: 'Error al guardar los datos'}, false)
      } else { reportAction('order', false, { }, true) }
    }
  })

  const handleEditOrder = () => { 
    let data = getValues("products")
    let array_data = []
    data.forEach((d, i) => {
      let objTemp = { id: d._id, order: i }
      array_data.push(objTemp)
    })
    mutation.mutate({ params: array_data })
  }
  
  const handleClose = () => { reset(); close() }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancelar</Button>
      <Button variant="contained" color="info" onClick={() => handleEditOrder()}>Modificar</Button>
      </> : <CircularProgress /> }
  </>)

  /* Drag and Drop */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = getValues("products");
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setValue('products', updatedList)
  }

  return (<>
    <FlexibleWidthDialog open={ open } title={ `Ordenar productos` } actions={ <Actions /> }>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="droppable">
          { (provided) => (
            <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
            { (fTBP.length > 0) ? (
            fTBP.map((item, i) => (
              <Draggable key={item.id} draggableId={item.id} index={i}>
                {(provided) => (
                  <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                    <ImageGalleryCard
                      image={item.facade} 
                      description={item.name}
                    />
                  </Grid>
                )}
              </Draggable>
            )) ) : <Grid item xs={12}> <Typography variant="inherit">No hay productos agregados</Typography> </Grid> }
            {provided.placeholder}
            </Grid>
          ) }
        </Droppable>
      </DragDropContext>
    </FlexibleWidthDialog>
  </>)
}