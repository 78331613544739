import { yupResolver } from "@hookform/resolvers/yup";
import { Button, IconButton, InputLabel, TextField } from "@mui/material";
import { Image as ImageIcon} from "@mui/icons-material"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { ValidationSchemaImageGallery } from "domain/Product/services/SchemaValidation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ImageInput from "components/Image/ImageInput";
import MediaModal from "domain/Media/MediaModal";

export default function Edit({ open, close, rsdId, instance, action }) {
  const [dataInstance, setDataInstance] = useState({})
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaImageGallery) 
  })

  useEffect(() => {
    //console.log(instance)
    setDataInstance(instance)
    setValue("image", instance.image)
    setValue("description", instance.description)
  }, [open])

  const handleClose = () => {
    setDataInstance({})
    reset()
    close()
  }

  const handleEdit = (params) => {
    //console.log(params)
    action(params)
    handleClose()
  }

  const handleChangeData = (event) => { setDataInstance({...dataInstance, [event.target.name]: event.target.value}) }

  /*Handlers Image manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = ( prop ) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = ( prop ) => { setDataFieldImageManager(prop) }
  const handleSelectImageManager = (url) => {
    setDataInstance({...dataInstance, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  const Actions = () => (<>
    <Button onClick={handleClose}>Cancelar</Button>
    <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
  </> )

  return (<>
    <FlexibleWidthDialog open={open} title="Editar imagen" actions={<Actions />}>
    <div style={{ display: "grid", gridTemplateColumns: "40% 60%"}}>
      <ImageInput image={ dataInstance.image }/>
      <div>
        <InputLabel htmlFor='image' required style={{ fontSize: 14 }}>Url de la imagen</InputLabel>
        <TextField id='image' fullWidth margin="dense" type="text" name='image'
          value={dataInstance.image || ""}
          {...register(`image`)} 
          error={errors.image ? true: false}
          onChange={handleChangeData} />
        <ValidateErrorMessage message={errors.image?.message} />
        <IconButton onClick={ () => { handleFieldImageManager('image'); handleOpenImageManager(); } }><ImageIcon /></IconButton>
      </div>
    </div>
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <TextField id='description' fullWidth margin="dense" type="text" name='description' multiline maxRows={4}
      value={dataInstance.description || ""}
      {...register(`description`)} 
      error={errors.description ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errors.description?.message} />
    </FlexibleWidthDialog>
    <MediaModal open={imageManagerModalOpen} setOpen={handleCloseImageManager} mediaSelected={handleSelectImageManager} path={`real-state-developments/${rsdId}/gallery`} />
  </>)
}