

export const Preview = ({
  title, 
  color_button_titlebar_color,
  color_button_titlebar_font_color,
  color_chat_background_color,
  color_font_color,
  color_bot_message,
  color_user_message,
  color_send_icon
}) => {
  return (
    <div style={{ width: 370, height: 370, borderRadius: 4, boxShadow: "rgba(0, 0, 0, 0.24) 1px 4px 15px 0px", backgroundColor: color_chat_background_color, position: "sticky", top: 0 }}>
      <div style={{
        alignItems: "center",
        backgroundColor: color_button_titlebar_color,
        borderRadius: "5px 5px 0 0",
        boxshadow: "0px 3px 6px 0px #00000029",
        color: color_button_titlebar_font_color,
        display: "flex",
        fontFamily: "'Roboto', sans-serif",
        fontSize: 18,
        height: 50,
        justifyContent: "space-between",
        paddingLeft: 15,
      }}>
        {title}
      </div>
      <div style={{ display: "flex", flexDirection: "column-reverse", flex: "1 1 auto", minHeight: 210 }}>
        <div style={{ display: "flex", flexDirection: "column", height: 50 }}>
          <div style={{
            padding: 10,
            display: "flex",
            flexDirection: "column",
            flex: "1 1"
          }}>
            <div style={{
              background: color_bot_message,
              border: "1px solid #e0e0e0",
              borderRadius: 20,
              color: color_font_color,
              flex: "0 0 auto",
              fontFamily: "'Roboto', sans-serif",
              fontSize: 14,
              marginTop: 10,
              maxWidth: "calc(100% - 28px)",
              padding: "7px 16px",
              wordBreak: "break-word",
              wordWrap: "break-word",
              marginRight: 75,
              lineHeight: "1.4",
              alignSelf: "flex-start"

            }}>
              Mensaje de Bot
            </div>
            <div style={{
              background: color_user_message,
              border: "1px solid #e0e0e0",
              borderRadius: 20,
              color: color_font_color,
              flex: "0 0 auto",
              fontFamily: "'Roboto', sans-serif",
              fontSize: 14,
              marginTop: 10,
              maxWidth: "calc(100% - 28px)",
              padding: "7px 16px",
              wordBreak: "break-word",
              wordWrap: "break-word",
              marginLeft: 75,
              lineHeight: "1.4",
              alignSelf: "flex-end"
            }}>
              Mensaje de usuario
            </div>
          </div>
          <div style={{ alignItems: "center", backgroundColor: "white", borderTop: "1px solid #eeeeee", display: "flex", fontfamily: "'Roboto', sans-serif", height: 50, zindex: 2 }} >
            <input type="text" placeholder="Haz una pregunta..." style={{
              backgroundColor: "white", border: "none", borderRadius: "0 0 4px 4px", color: "rgba(0,0,0,0.87)", fontSize: 14, paddingLeft: 15, width: "100%",
            }} />
            <svg xmlns="http://www.w3.org/2000/svg" id="sendIcon" style={{
              cursor: "pointer",
              fill: color_send_icon,
              flex: "0 0 auto",
              height: 24,
              margin: 15,
              viewbox: "0 0 24 24",
              width: 24,
            }}>
              <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}