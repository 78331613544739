import { useEffect, useState } from "react";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from 'react-query'
import { ValidationSchemaCRMProperties } from "../services/SchemaValidation"
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { editRealStateDevelopmentCRMProperties } from "services/api/RealStateDevelopment";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import { useForm } from "react-hook-form";

export default function CRMProperties({ open, close, instance, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState([])
  let { reset, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(ValidationSchemaCRMProperties)
  })

  useEffect(() => {
    if (open) {
      console.log("CRMProperties", instance)
      setDataInstance(instance.crmProperties !== undefined ? instance.crmProperties : {} )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(editRealStateDevelopmentCRMProperties, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleEdit = (params) => {
    console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: params })
  }

  const handleClose = () => { reset(); setDataInstance([]); close() }

  const handleChangeData = (event) => { //console.log("handleChangeDataSelect", event.target.value)
    if(event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} actions={<Actions />} title={<>CRM Propiedades - {instance.name} </>} >
      <InputLabel htmlFor="medio" required style={{ fontSize: 14 }}>Medio</InputLabel>
      <TextField id="medio" fullWidth margin="dense" type="text" size="small"
        value={dataInstance.medio || ""}
        {...register(`medio`)}
        error={errors.medio ? true : false}
        onChange={(event) => handleChangeData(event)} />
      <ValidateErrorMessage message={errors.medio?.message} />
      <br />
      <InputLabel htmlFor="tags" required style={{ fontSize: 14 }}>Tags</InputLabel>
      <TextField id="tags" fullWidth margin="dense" type="text" size="small"
        value={dataInstance.tags || ""}
        {...register(`tags`)}
        error={errors.tags ? true : false}
        onChange={(event) => handleChangeData(event)} />
      <ValidateErrorMessage message={errors.tags?.message} />
    </FlexibleWidthDialog>
  </>)
}