export const API_BASE_URL = "https://crm-products-api-b1-sn2jcgssxq-uc.a.run.app/api/v1";
//export const API_BASE_URL = "http://localhost:8000/api/v1";

export const HEADERS_WITH_TOKEN = (token) => {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
    //'Authorization': `Bearer ${localStorage.getItem('crmprodtkn')}`
  }
}

export const HEADERS_WITH_TOKEN_WITHOUT_CONTENTTYPE = (token) => {
  return {
    'Authorization': `Bearer ${token}`
  }
}