import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  url: Yup.string().required("Campo requerido"),
  redirect: Yup.string().required("Campo requerido"),
  type: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  //value: Yup.string().required("Campo requerido"),
  value: Yup.string().when('type', {
    is: (type) => { return type === 2 || type === 3 },
    then: Yup.string().required("Campo requerido"),
    otherwise: Yup.string().nullable()
  }),
  active: Yup.boolean().required("Campo requerido"),
})