import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { makeStyles } from "@mui/styles";
import Menu from "./Menu";

const drawerWidth = 251;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

const Drawer = ({ open, actionOpen }) => {
  const classes = useStyles()
  const theme = useTheme();
  const matchesDownMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <nav className={classes.drawer}>
      { matchesDownMD ?
      <SwipeableDrawer
        open={open} 
        onClose={() => actionOpen()} 
        onOpen={() => actionOpen()} 
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#e76627', color: '#fff' },
        }}
      >
        <Menu action={ actionOpen }/>
      </SwipeableDrawer>
      :
      <SwipeableDrawer
        variant="permanent"
        open
        onClose={() => console.log("close")} 
        onOpen={() => console.log("open")} 
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRight: 0, backgroundColor: '#e76627', color: '#fff' },
        }}
      >
        <Menu action={ actionOpen }/>
      </SwipeableDrawer>
      }
    </nav>
  );
};

export default Drawer;
