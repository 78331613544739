//import logo from './logo.svg';
import './App.css';
import './General.css';
import './Components.css';
import './styles/realStateDevelopment.css';
// import './lexicalStyles.css';
import { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import ThemeMUIProvider from 'theme/MainTheme';
import Dashboard from 'domain/Dashboard';
import Login from 'domain/Login';
import City from 'domain/City';
import Region from 'domain/Region';
//import RealStateDevelopment from 'domain/RealStateDevelopment';
import Credit from 'domain/Credit';
import KindProduct from 'domain/KindProduct';
import Banner from 'domain/Banner';
import Product from 'domain/Product';
import { AuthContext } from 'context/AuthContext';
import Layout from 'components/Layout';
import User from 'domain/User';
import Redirect from 'domain/Redirect';
import RealStateDevelopments from 'domain/RealStateDevelopments';
import BannerMultipleLinks from 'domain/BannerMultipleLinks';
import UpdateProducts from 'domain/UpdateProducts';
import WhastAppTrakingButton from 'domain/Report/WhatsAppTracking';
import WebDownload from 'domain/WebDownload';

const ProtectRoute = ({ children, roles }) => {
  const navigate = useNavigate()
  const { isAuth, globalUser } = useContext(AuthContext)
  if (!isAuth) { navigate("/admin") }
  if (globalUser === false || !roles.includes(JSON.parse(globalUser).role.identifier)) {
    navigate("/admin")
  }
  return children
}

function App() {

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") { document.activeElement.blur(); }
    });
  }, [])

  return (
    <ThemeMUIProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/admin/*' element={<Layout />} >
            <Route index element={<Login />} />
            <Route path='dashboard' element={<ProtectRoute roles={["SUPADMIN", "ADMIN", "SADASIUSER"]}> <Dashboard /> </ProtectRoute>} />
            <Route path='update-products' element={<ProtectRoute roles={["SUPADMIN", "ADMIN", "SADASIUSER"]}> <UpdateProducts /> </ProtectRoute>} />
            <Route path='cities' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <City /> </ProtectRoute>} />
            <Route path='regions' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <Region /> </ProtectRoute>} />
            <Route path='credits' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <Credit /> </ProtectRoute>} />
            <Route path='kind-products' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <KindProduct /> </ProtectRoute>} />
            {/* <Route path='real-state-developments' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <RealStateDevelopment /> </ProtectRoute>} /> */}
            <Route path='real-state-developments' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <RealStateDevelopments /> </ProtectRoute>} />
            <Route path='products' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <Product /> </ProtectRoute>} />
            <Route path='banners' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <Banner /> </ProtectRoute>} />
            <Route path='banners-multiple-links' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <BannerMultipleLinks /> </ProtectRoute>} />
            <Route path='redirects' element={<ProtectRoute roles={["SUPADMIN", "ADMIN"]}> <Redirect /> </ProtectRoute>} />
            <Route path='users' element={<ProtectRoute roles={["SUPADMIN"]}> <User /> </ProtectRoute>} />
            <Route path='reports/whatsapp-tracking' element={<ProtectRoute roles={["SUPADMIN", "ADMIN", "SADASIUSER"]}> <WhastAppTrakingButton /> </ProtectRoute>} />
          </Route>
          <Route path='web-download' element={<WebDownload />} />
          <Route path="*" element={<Navigate to="/admin" />} />
        </Routes>
      </BrowserRouter>
    </ThemeMUIProvider>
  );
}

export default App;

/* https://grupoargentilia.mx/frascati
https://grupoargentilia.mx/frascati/campestre/
https://grupoargentilia.mx/frascati/aeropuerto/
https://grupoargentilia.mx/frascati/#servicios */