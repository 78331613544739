import React, { useState } from "react";
import { Button, CircularProgress, InputLabel, TextField, Typography } from "@mui/material";
import { useMutation } from 'react-query'
import { editProductPrice } from "services/api/Product";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Price({ open, close, reportAction, instance }) {
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState({
    price: '',
    confirm_price: ''
  });

  const mutation = useMutation(editProductPrice, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', "error")
      } else { reportAction(true, 'Datos guardados correctamente', "success") }
    }
  })

  const handleEdit = () => { 
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: { price: values.price } })
  }
  
  const handleClose = () => { setValues({ price: '0', confirm_price: '0' }); close() }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancelar</Button>
      <Button variant="contained" color="info" onClick={handleEdit} disabled={values.price !== values.confirm_price || values.price === "0" || values.price === ""}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <FlexibleWidthDialog open={ open } title={ `Editar precio - ${instance.name}` } actions={ <Actions /> }>
      <Typography variant="h5">Precio actual: ${instance.price.toLocaleString("es-MX")}</Typography><br/>
      <InputLabel htmlFor='formatted-price' style={{ fontSize: 18 }}>Precio</InputLabel>
      <TextField
        value={values.price}
        fullWidth
        autoFocus
        size="small"
        onChange={handleChange}
        name="price"
        id="formatted-price-input"
        inputProps={{style: { textAlign: 'center' }}}
        InputProps={{
          sx:{ fontSize: 30 },
          inputComponent: NumberFormatCustom,
        }}
        variant="outlined"
      />
      <br /><br />
      <InputLabel htmlFor='formatted-confrim-price' style={{ fontSize: 18 }}>Confirmar precio</InputLabel>
      <TextField
        value={values.confirm_price}
        fullWidth
        size="small"
        onChange={handleChange}
        name="confirm_price"
        id="formatted-confrim-price"
        inputProps={{style: { textAlign: 'center' }}}
        InputProps={{
          sx:{ fontSize: 30 },
          inputComponent: NumberFormatCustom,
        }}
        variant="outlined"
      />
      <br /><br />
      {values.price !== values.confirm_price ?  
      <Typography>Ambos campos deben contener el mismo monto para poder editar el precio</Typography> : null}

    </FlexibleWidthDialog>
  </>)
}