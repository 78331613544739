import { Paper, InputBase, Divider } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

export default function ToolBar({ searchFunction }) {
  return (
    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', maxWidth: 450, minWidth: 200 }} >
      <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Buscar" onChange={searchFunction} inputProps={{ 'aria-label': 'search' }} />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <SearchIcon style={{ margin: 10 }}/>
    </Paper>
  )
}