import { Autocomplete, InputLabel, Switch, TextField, Typography } from "@mui/material";

import { ValidateErrorMessage } from "components/ValidateErrorMessage";

export default function GeneralDataForm({ dataInstance, formAttributes, changeDataInstance, rsdData }) {

  const handleChangeData = (field, value) => { changeDataInstance("general", field, value) }

  return ( <>
    <Typography variant="h6" gutterBottom>Datos Generales</Typography><br />
    <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
    <TextField id='name' fullWidth margin="dense" type="text" name='name'
      value={dataInstance.name || ""}
      {...formAttributes.register(`name`)} 
      error={formAttributes.errors.name ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.name?.message} />
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <TextField id='description' fullWidth margin="dense" type="text" name='description' multiline maxRows={5}
      value={dataInstance.description || ""}
      {...formAttributes.register(`description`)} 
      error={formAttributes.errors.description ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.description?.message} />
    <br />
    <InputLabel htmlFor='link' required style={{ fontSize: 14 }}>Link</InputLabel>
    <TextField id='link' fullWidth margin="dense" type="text" name='link' multiline maxRows={5}
      value={dataInstance.link || ""}
      {...formAttributes.register(`link`)} 
      error={formAttributes.errors.link ? true: false}
      onChange={ (e) => handleChangeData(e.target.name, e.target.value) } />
    <ValidateErrorMessage message={formAttributes.errors.link?.message} />
    <br />
    <InputLabel htmlFor='show_in_home' required style={{ fontSize: 14 }}>Aparece en home?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-show_in_home` }}
      onClick={ () => handleChangeData('show_in_home', !dataInstance.show_in_home) }
      checked={dataInstance.show_in_home || false} 
      {...formAttributes.register(`show_in_home`)} 
    />
    <br />
    <InputLabel htmlFor='show_in_home_alttahomes' required style={{ fontSize: 14 }}>Aparece en home en Altta Homes?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-show_in_home_alttahomes` }}
      onClick={ () => handleChangeData('show_in_home_alttahomes', !dataInstance.show_in_home_alttahomes) }
      checked={dataInstance.show_in_home_alttahomes || false} 
      {...formAttributes.register(`show_in_home_alttahomes`)} 
    />
    <br />
    <InputLabel htmlFor='show_in_home_heroes' required style={{ fontSize: 14 }}>Aparece en home en Héroes?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-show_in_home_heroes` }}
      onClick={ () => handleChangeData('show_in_home_heroes', !dataInstance.show_in_home_heroes) }
      checked={dataInstance.show_in_home_heroes || false} 
      {...formAttributes.register(`show_in_home_heroes`)} 
    />
    <br />
    <InputLabel htmlFor='show_in_home_mihogar' required style={{ fontSize: 14 }}>Aparece en home en Mi hogar en el Caribe?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-show_in_home_mihogar` }}
      onClick={ () => handleChangeData('show_in_home_mihogar', !dataInstance.show_in_home_mihogar) }
      checked={dataInstance.show_in_home_mihogar || false} 
      {...formAttributes.register(`show_in_home_mihogar`)} 
    />
    <br />
    <InputLabel htmlFor='real_state_developments' style={{ fontSize: 14 }}>Selecciona los desarrollos en los que aparecerá el banner</InputLabel><br />
    <Autocomplete multiple id="real_state_developments"
      options={ rsdData }
      getOptionLabel={ (option) => option.name }
      defaultValue={ dataInstance.real_state_developments ? rsdData.filter(value => dataInstance.real_state_developments.includes(value._id)) : [] }
      onChange={ (event, newValue) => { handleChangeData("real_state_developments", newValue.map(value => value._id)) } }
      renderInput={(params) => ( <TextField {...params} variant="outlined" placeholder="Desarrollos"/> )} />
    <ValidateErrorMessage message={formAttributes.errors.real_state_developments?.message} />
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Activa?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
      onClick={ () => handleChangeData('active', !dataInstance.active) }
      checked={dataInstance.active || false} 
      {...formAttributes.register(`active`)} 
    />
  </> )
}