import { InputLabel, TextField, Typography } from "@mui/material";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { modulesRQ } from "services/helpers/ReactQuillOptions";

export default function UbicationContactForm({ dataInstance, register, errors, handleOnChangeData, handleChangeRichEditor }) {
  return ( <>
    <Typography variant="inherit" component="h4"> Ubicación </Typography>
    <br />
    <InputLabel htmlFor='address' required style={{ fontSize: 14 }}>Dirección</InputLabel>
    <div> <ReactQuill value={dataInstance.ubication?.address || ""} modules={modulesRQ} onChange={(value) => { handleChangeRichEditor('ubication.address', value) }}/> </div>
    <ValidateErrorMessage message={errors.ubication?.address?.message} />
    <br />
    <InputLabel htmlFor='google_maps' required style={{ fontSize: 14 }}>Google Maps</InputLabel>
    <TextField id='google_maps' fullWidth margin="dense" type="text" name='google_maps'
      value={dataInstance.ubication?.google_maps || ""} 
      {...register(`ubication.google_maps`)} error={errors.ubication?.google_maps ? true: false} 
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.ubication?.google_maps?.message} />
    <br />
    <InputLabel htmlFor='latitude' required style={{ fontSize: 14 }}>Latitud</InputLabel>
    <TextField id='latitude' fullWidth margin="dense" type="number" name='latitude'
      value={dataInstance.ubication?.latitude || ""} 
      {...register(`ubication.latitude`)} error={errors.ubication?.latitude ? true: false} 
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.ubication?.latitude?.message} />
    <br />
    <InputLabel htmlFor='longitude' required style={{ fontSize: 14 }}>Longitud</InputLabel>
    <TextField id='longitude' fullWidth margin="dense" type="number" name='longitude'
      value={dataInstance.ubication?.longitude || ""} 
      {...register(`ubication.longitude`)} error={errors.ubication?.longitude ? true: false} 
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.ubication?.longitude?.message} />
    <br />
    

    <Typography variant="inherit" component="h4"> Contacto </Typography>
    <br />
    <InputLabel htmlFor='email' required style={{ fontSize: 14 }}>Correo electrónico</InputLabel>
    <TextField id='email' fullWidth margin="dense" type="text" name='email'
      value={dataInstance.contact?.email || ""} 
      {...register(`contact.email`)} error={errors.contact?.email ? true: false} 
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.contact?.email?.message} />
    <br />
    <InputLabel htmlFor='phone' required style={{ fontSize: 14 }}>Teléfono</InputLabel>
    <TextField id='phone' fullWidth margin="dense" type="text" name='phone'
      value={dataInstance.contact?.phone || ""} 
      {...register(`contact.phone`)} error={errors.contact?.phone ? true: false} 
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.contact?.phone?.message} />
    <br />
    <InputLabel htmlFor='whatsapp' style={{ fontSize: 14 }}>Whatsapp</InputLabel>
    <TextField id='whatsapp' fullWidth margin="dense" type="text" name='whatsapp'
      value={dataInstance.contact?.whatsapp || ""} 
      {...register(`contact.whatsapp`)} error={errors.contact?.whatsapp ? true: false} 
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.contact?.whatsapp?.message} />
    <br />
  </> )
}