import { Autocomplete, IconButton, InputLabel, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { Image as ImageIcon } from "@mui/icons-material";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { modulesRQ } from "services/helpers/ReactQuillOptions";
import { JsonEditor as Editor } from "jsoneditor-react";
import 'jsoneditor-react/es/editor.min.css'
import ace from 'brace'
import 'brace/mode/json';
import 'brace/theme/github';

export default function GeneralDataForm({ dataInstance, register, errors, handleOnChangeData, handleChangeDataBoolean, handleChangeDataSelect, handleOpenImageManager, handleFieldImageManager, handleChangeRichEditor, handleChangeJSONEditor, regionsData, citiesData, kindProductsData }) {
  return (<>
    <Typography variant="inherit" component="h4"> Datos Generales </Typography>
    <br />
    <InputLabel htmlFor='slug' required style={{ fontSize: 14 }}>Slug</InputLabel>
    <TextField id='slug' fullWidth margin="dense" type="text" name='slug'
      value={dataInstance.slug || ""}
      {...register(`slug`)} error={errors.slug ? true : false}
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.slug?.message} />
    <br />
    <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
    <TextField id='name' fullWidth margin="dense" type="text" name='name'
      value={dataInstance.name || ""}
      {...register(`name`)} error={errors.name ? true : false}
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.name?.message} />
    <br />
    <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
      <div style={{ textAlign: "center" }}>
        <img src={dataInstance.logo || ""} style={{ width: "90%" }} />
      </div>
      <div>
        <InputLabel htmlFor='logo' required style={{ fontSize: 14 }}>Logo</InputLabel>
        <TextField id='logo' fullWidth margin="dense" type="text" name='logo'
          value={dataInstance.logo || ""}
          {...register(`logo`)} error={errors.logo ? true : false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.logo?.message} />
        <IconButton onClick={() => { handleFieldImageManager('logo'); handleOpenImageManager(); }}><ImageIcon /></IconButton>
      </div>
    </div>
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <div> <ReactQuill value={dataInstance.description || ""} modules={modulesRQ} onChange={(value) => { handleChangeRichEditor('description', value) }} /> </div>
    <ValidateErrorMessage message={errors.description?.message} />
    <br />
    <InputLabel htmlFor='region' required style={{ fontSize: 14 }}>Región</InputLabel>
    <TextField id="region" fullWidth margin="dense" name='region' select
      value={dataInstance.region || []}
      {...register(`region`)} error={errors.region ? true : false}
      onChange={handleOnChangeData} >
      {regionsData.map((region) => (<MenuItem key={region._id} value={region._id}> {region.name} </MenuItem>))}
    </TextField>
    <ValidateErrorMessage message={errors.region?.message} />
    <br />
    <InputLabel htmlFor='city' required style={{ fontSize: 14 }}>Ciudad</InputLabel>
    <TextField id="city" fullWidth margin="dense" name='city' select
      value={dataInstance.city || []}
      {...register(`city`)} error={errors.city ? true : false}
      onChange={handleOnChangeData} >
      {citiesData.map((city) => (<MenuItem key={city._id} value={city._id}> {city.name} </MenuItem>))}
    </TextField>
    <ValidateErrorMessage message={errors.city?.message} />
    <br />
    <InputLabel htmlFor='kind_products' style={{ fontSize: 14 }}>Tipo de Productos</InputLabel><br />
    <Autocomplete multiple id="kind_products"
      options={kindProductsData}
      getOptionLabel={(option) => option.name}
      defaultValue={dataInstance.kind_products ? kindProductsData.filter(value => dataInstance.kind_products.includes(value._id)) : []}
      onChange={(event, newValue) => { handleChangeDataSelect("kind_products", newValue.map(value => value._id)) }}
      renderInput={(params) => (<TextField {...params} variant="outlined" placeholder="Opciones" />)} />
    <ValidateErrorMessage message={errors.kind_products?.message} />
    <br />
    <InputLabel htmlFor='show_in_random_image_home' required style={{ fontSize: 14 }}>Mostrar en imagenes aleatorias en home?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-show_in_random_image_home` }}
      onClick={() => handleChangeDataBoolean('show_in_random_image_home')}
      checked={dataInstance.show_in_random_image_home || false}
      {...register(`show_in_random_image_home`)}
    />
    <br />
    <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
      <div style={{ textAlign: "center" }}>
        <img src={dataInstance.random_image_home || ""} style={{ width: "90%" }} />
      </div>
      <div>
        <InputLabel htmlFor='random_image_home' required={dataInstance.show_in_random_image_home} style={{ fontSize: 14 }}>Imagen aleatoria en home</InputLabel>
        <TextField id='random_image_home' fullWidth margin="dense" type="text" name='random_image_home'
          value={dataInstance.random_image_home || ""}
          {...register(`random_image_home`)} error={errors.random_image_home ? true : false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.random_image_home?.message} />
        <IconButton onClick={() => { handleFieldImageManager('random_image_home'); handleOpenImageManager(); }}><ImageIcon /></IconButton>
      </div>
    </div>
    <br />
    <InputLabel htmlFor='show_in_featured_image_home' required style={{ fontSize: 14 }}>Mostrar en imagenes destacadas en home?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-show_in_featured_image_home` }}
      onClick={() => handleChangeDataBoolean('show_in_featured_image_home')}
      checked={dataInstance.show_in_featured_image_home || false}
      {...register(`show_in_featured_image_home`)}
    />
    <br />
    <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
      <div style={{ textAlign: "center" }}>
        <img src={dataInstance.featured_image_home || ""} style={{ width: "90%" }} />
      </div>
      <div>
        <InputLabel htmlFor='featured_image_home' required={dataInstance.show_in_featured_image_home} style={{ fontSize: 14 }}>Imagen destacada en home</InputLabel>
        <TextField id='featured_image_home' fullWidth margin="dense" type="text" name='featured_image_home'
          value={dataInstance.featured_image_home || ""}
          {...register(`featured_image_home`)} error={errors.featured_image_home ? true : false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.featured_image_home?.message} />
        <IconButton onClick={() => { handleFieldImageManager('featured_image_home'); handleOpenImageManager(); }}><ImageIcon /></IconButton>
      </div>
    </div>
    <br />
    <InputLabel htmlFor='brochure' style={{ fontSize: 14 }}>Brochure</InputLabel>
    <TextField id='brochure' fullWidth margin="dense" type="text" name='brochure'
      value={dataInstance.brochure || ""}
      {...register(`brochure`)} error={errors.brochure ? true : false}
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.brochure?.message} />
    <br />
    <InputLabel htmlFor='structured_data' style={{ fontSize: 14 }}>Datos estructurados</InputLabel>
    <div style={{ height: "70vh" }}>
      <Editor
        value={dataInstance.structured_data ? JSON.parse(dataInstance.structured_data) : ""}
        onChange={(data) => { handleChangeJSONEditor("structured_data", JSON.stringify(data)) }}
        ace={ace} mode="code"
        theme="ace/theme/github"
      />
    </div>
    <ValidateErrorMessage message={errors.structured_data?.message} />
    <br />
    <InputLabel htmlFor='activeInAmigoSadasi' required style={{ fontSize: 14 }}>Activa en Amigo Sadasi?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInAmigoSadasi` }}
      onClick={() => handleChangeDataBoolean('activeInAmigoSadasi')}
      checked={dataInstance.activeInAmigoSadasi || false}
      {...register(`activeInAmigoSadasi`)}
    />
    <br />
    <InputLabel htmlFor='activeInAmigoSadasiRJR' required style={{ fontSize: 14 }}>Activa en Amigo Sadasi RJR?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInAmigoSadasiRJR` }}
      onClick={() => handleChangeDataBoolean('activeInAmigoSadasiRJR')}
      checked={dataInstance.activeInAmigoSadasiRJR || false}
      {...register(`activeInAmigoSadasiRJR`)}
    />
    <br />
    <InputLabel htmlFor='activeInAlttaHomes' required style={{ fontSize: 14 }}>Activa en AlttaHomes?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInAlttaHomes` }}
      onClick={() => handleChangeDataBoolean('activeInAlttaHomes')}
      checked={dataInstance.activeInAlttaHomes || false}
      {...register(`activeInAlttaHomes`)}
    />
    <br />
    <InputLabel htmlFor='activeInHeroes' required style={{ fontSize: 14 }}>Activa en Héroes?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInHeroes` }}
      onClick={() => handleChangeDataBoolean('activeInHeroes')}
      checked={dataInstance.activeInHeroes || false}
      {...register(`activeInHeroes`)}
    />
    <br />
    <InputLabel htmlFor='activeInMiHogar' required style={{ fontSize: 14 }}>Activa en Mi Hogar en el caribe?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-activeInMiHogar` }}
      onClick={() => handleChangeDataBoolean('activeInMiHogar')}
      checked={dataInstance.activeInMiHogar || false}
      {...register(`activeInMiHogar`)}
    />
    <br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Activo?</InputLabel>
    <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
      onClick={() => handleChangeDataBoolean('active')}
      checked={dataInstance.active || false}
      {...register(`active`)}
    />
    <br />
  </>)
}