import { useContext, useEffect, useState } from "react";
import { Badge, Fab, IconButton, InputBase, List, Paper, Tooltip } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon, Image as ImageIcon, Architecture as ArchitectureIcon, ViewInAr as ViewInArIcon, Layers as LayersIcon, Settings as SettingsIcon, ViewList as ViewListIcon, Code as CodeIcon, Search as SearchIcon, Add as AddIcon, Close as CloseIcon } from '@mui/icons-material'
import { useQuery, useQueryClient } from "react-query"
import { AuthContext } from "context/AuthContext";
import { getBasicListRealStateDevelopments } from "services/api/RealStateDevelopment";
import { getProductsByRealStateDevelopment } from "services/api/Product";
import { getKindProducts } from "services/api/KindProduct";
import SectionIndexAdmin from "components/Loaders/SectionIndexAdmin";
import { SnackbarGlobal } from "components/Snackbar";
import TypeOne from "components/Card/TypeOne";
import Add from "./Add";
import Edit from "./Edit";
import ImagesGallery from "./ImagesGallery";
import ArchitecturalPlan from "./ArchitecturalPlan";
import VirtualTour from "./VirtualTour";
import Floor from "./Floor";
import Remove from "./Remove";
import Feature from "./Features";
import Order from "./Order";
import Code from "./Code";
import RSDlist from "components/List/RSDList";
// import FeaturesBulk from "./helpers/FeaturesBulk";
// import FloorsBulk from "./helpers/FloorsBulk";
// import VirtualTourBulk from "./helpers/VirtualTourBulk";

export default function Product() {
  const { setTitlePage } = useContext(AuthContext);
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openImageGallery, setOpenImageGallery] = useState(false)
  const [openArchitecturalPlan, setOpenArchitecturalPlan] = useState(false)
  const [openVirtualTour, setOpenVirtualTour] = useState(false)
  const [openHowToGet, setOpenHowToGet] = useState(false)
  const [openFeature, setOpenFeature] = useState(false)
  const [openOrder, setOpenOrder] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openCode, setOpenCode] = useState(false)
  // const [openActionHelper, setOpenActionHelper] = useState(0)
  const [instanceSelected, setInstanceSelected] = useState({})
  const [instanceRSDSelected, setInstanceRSDSelected] = useState(null)
  const [rsdSelected, setRsdSelected] = useState(null)
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [authError, setAuthError] = useState(false);
  const [dataFilterProducts, setDataFilterProducts] = useState(null);
  const [dataFilter, setDataFilter] = useState(null)
  const queryClient = useQueryClient()
  const { isLoading, isFetching, error, data } = useQuery(
    'realStateDevelopmentsList', getBasicListRealStateDevelopments, { refetchOnWindowFocus: false }
  )
  const { data: dProducts } = useQuery(
    ['productsList', rsdSelected],
    () => getProductsByRealStateDevelopment(rsdSelected),
    { refetchOnWindowFocus: false, enabled: (rsdSelected !== null && rsdSelected !== undefined && rsdSelected !== "") }
  )
  const { data: dKindProducts } = useQuery(
    'kindProductsList', getKindProducts, { refetchOnWindowFocus: false }
  )

  useEffect(() => {
    setTitlePage('Productos');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => { if (data !== undefined && data !== null && data !== "") setDataFilter(data.data) }, [data])

  useEffect(() => { if (dProducts !== undefined && dProducts !== null && dProducts !== "") setDataFilterProducts(dProducts.data) }, [dProducts])

  const handleRefreshData = () => { queryClient.invalidateQueries('productsList') }

  const handleResetActions = (action) => {
    if (action === "add") setOpenAdd(false)
    if (action === "edit") setOpenEdit(false)
    if (action === "image-gallery") setOpenImageGallery(false)
    if (action === "architectural-plan") setOpenArchitecturalPlan(false)
    if (action === "virtual-tour") setOpenVirtualTour(false)
    if (action === "floor") setOpenHowToGet(false)
    if (action === "feature") setOpenFeature(false)
    if (action === "order") setOpenOrder(false)
    if (action === "delete") setOpenDelete(false)
    if (action === "code") setOpenCode(false)
  }

  const handleWatchAction = (action, cancel, error, success) => {
    if (cancel) return;
    if (!success) {
      setMessageSnackbar(error.message);
      setAuthError(true);
    } else {
      alert("Acción realizada correctamente")
      handleResetActions(action)
      handleRefreshData()
    }
  }

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const Actions = ({ item }) => (<>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenEdit(true) }}><EditIcon color="info" /></IconButton>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenImageGallery(true) }}><Badge badgeContent={item.image_gallery.length} color="primary"><ImageIcon /></Badge></IconButton>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenArchitecturalPlan(true) }}><Badge badgeContent={item.architectural_plans.length} color="primary"><ArchitectureIcon /></Badge></IconButton>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenVirtualTour(true) }}><Badge badgeContent={item.virtual_tours.length} color="primary"><ViewInArIcon /></Badge></IconButton>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenHowToGet(true) }}><Badge badgeContent={item.floors.length} color="primary"><LayersIcon /></Badge></IconButton>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenFeature(true) }}><Badge badgeContent={item.features.length} color="primary"><SettingsIcon /></Badge></IconButton>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenDelete(true) }}><DeleteIcon color="error" /></IconButton>
    <IconButton onClick={() => { setInstanceSelected(item); setOpenCode(true) }}><CodeIcon color="success" /></IconButton>
  </>)

  if (isLoading || isFetching) return <SectionIndexAdmin />
  if (error) return <div> Ha ocurrido un error </div>

  return (<div style={{ paddingBottom: 70 }}>
    <div className="rsd-container">
      <div className="rsd-left-siderbar-container">
        <Paper sx={{ p: '2px 4px', mb: 1, display: 'flex', alignItems: 'center', width: '100%' }} >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Buscar" onChange={handleSearch} inputProps={{ 'aria-label': 'search' }} />
          <SearchIcon style={{ margin: 10 }} />
        </Paper>
        <div className="rsd-left-siderbar-main">
          {(dataFilter !== undefined && dataFilter !== null) ? <>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {dataFilter.map((rsd, index) => (
                <RSDlist key={`${index}-${rsd._id}`} {...rsd} onClick={() => { setInstanceRSDSelected(rsd); setRsdSelected(rsd._id) }} isFocus={rsd._id === rsdSelected} />
              ))}
            </List>
          </> : null}
        </div>
      </div>
      <div className="rsd-main">
        {rsdSelected === null ? <p>Selecciona un desarrollo para poder acceder a sus productos</p> : null}
        {rsdSelected !== null ? <>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", position: "sticky", top: 64, backgroundColor: "white", zIndex: 2, paddingBottom: 30 }} >
            <img src={instanceRSDSelected.logo} style={{ height: 60 }} alt="logotipo" />
            <span className="fab_close">
              <Tooltip title="Cerrar opciones"><Fab color="primary" size="small" onClick={() => { setInstanceRSDSelected(null); setRsdSelected(null) }}><CloseIcon /></Fab></Tooltip>
            </span>
            <div>
            <Tooltip title="Ordenar productos"><IconButton onClick={() => { setOpenOrder(true) }}><ViewListIcon /></IconButton></Tooltip>
            </div>
          </div>

          {(dataFilterProducts !== null && dataFilterProducts.length === 0) && <Paper elevation={1} className="without-data-container">
            <p> No hay datos a mostrar </p>
            <p> Agrega tu primer registro dando click en el botón <Fab color="primary" size="small" disabled><AddIcon /></Fab> del lado derecho inferior</p>
          </Paper>}
          <div className="grid-admin-product-container">
            {(dataFilterProducts !== null) && dataFilterProducts.map((item) => <>
              <TypeOne key={item._id} actions={<Actions item={item} />}
                name={<><p>{item.name}</p><p>({item._id})</p></>}
                logo={item.facade !== "" ? item.facade : '/logo_sadasi.png'}
                image={item.facade !== "" ? item.facade : '/logo_sadasi.png'} 
                active={item.active}/>
            </>
            )}
          </div>
        </> : null}
      </div>
    </div>
    {rsdSelected !== null ? <span className="fab_add">
      <Tooltip title="Agregar"><Fab color="primary" onClick={() => setOpenAdd(true)}><AddIcon /></Fab></Tooltip>
    </span> : null}
    <Add
      open={openAdd}
      close={() => handleResetActions("add")}
      reportAction={handleWatchAction}
      realStateDevelopment={rsdSelected}
      extraData={{ dKindProducts }} />
    <Edit
      open={openEdit}
      close={() => handleResetActions("edit")}
      instance={instanceSelected}
      realStateDevelopment={rsdSelected}
      reportAction={handleWatchAction}
      extraData={{ dKindProducts }} />
    <ImagesGallery
      open={openImageGallery}
      close={() => handleResetActions("image-gallery")}
      instance={instanceSelected}
      reportAction={handleWatchAction} />
    <ArchitecturalPlan
      open={openArchitecturalPlan}
      close={() => handleResetActions("architectural-plan")}
      instance={instanceSelected}
      reportAction={handleWatchAction} />
    <VirtualTour
      open={openVirtualTour}
      close={() => handleResetActions("virtual-tour")}
      instance={instanceSelected}
      reportAction={handleWatchAction} />
    <Floor
      open={openHowToGet}
      close={() => handleResetActions("floor")}
      instance={instanceSelected}
      reportAction={handleWatchAction} />
    <Feature
      open={openFeature}
      close={() => handleResetActions("feature")}
      instance={instanceSelected}
      reportAction={handleWatchAction} />
    {dProducts !== undefined &&
      <Order
        open={openOrder}
        close={() => handleResetActions("order")}
        instance={dProducts.data}
        reportAction={handleWatchAction} />
    }
    <Remove
      open={openDelete}
      close={() => handleResetActions("delete")}
      instance={instanceSelected}
      reportAction={handleWatchAction}
    />
    <Code
      open={openCode}
      close={() => handleResetActions("code")}
      instance={instanceSelected}
    />
    {/* <VirtualTourBulk
      open={ openActionHelper === 1 }
      close={ () => setOpenActionHelper(0) }
      /> */}
    {/* <FloorsBulk
    open={ openActionHelper === 2 }
    close={ () => setOpenActionHelper(0) }
    /> */}
    {/* <FeaturesBulk
    open={ openActionHelper === 3 }
    close={ () => setOpenActionHelper(0) }
    /> */}
    <SnackbarGlobal
      vertical='bottom'
      horizontal='center'
      open={authError}
      close={() => setAuthError(false)}
      message={messageSnackbar} />
  </div>)
}