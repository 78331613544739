import { useState } from "react";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { Save as SaveIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon, Add as AddIcon } from "@mui/icons-material";
import { useMutation } from "react-query";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; 

import { ValidationSchema, ValidationSchemaImage, ValidationSchemaOption } from "../services/SchemaValidation";
import FullScreenDialog from "components/Dialog/FullScreenDialog";
import ImageLeftCard from "components/Card/ImageLeftCard";
import DialogImageManager from "domain/ImageManager/DialogImageManager";
import ImageForm from "../modules/ImageForm";
import GeneralDataForm from "../modules/GeneralDataForm";
import MediaModal from "domain/Media/MediaModal";
import { saveBannerMultipleLinks } from "services/api/BannerMultipleLinks";
import OptionForm from "../modules/OptionForm";

export default function Add({ open, close, reportAction }) {
  const schemaBanner = { image: "", view: "" }
  const schemaOption = { name: "", link: "" }
  const [isLoading, setIsLoading] = useState(false);
  const [dataInstanceData, setDataInstanceData] = useState({})
  const [dataInstanceImage, setDataInstanceImage] = useState({})
  const [dataInstanceOption, setDataInstanceOption] = useState({})
  const [mode, setMode] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [showFormOption, setShowFormOption] = useState(false)
  const [editPosition, setEditPosition] = useState(null)
  const [imageManagerModalOpen, setImageManagerModalOpen] = useState(false)
  const [dataFieldImageManager, setDataFieldImageManager] = useState(null)
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaImage) 
  }) // useForm para Imagen
  let { reset: rOPT, handleSubmit: hOPT, register: regOPT, formState: { errors: eOPT }, setValue: sVOPT } = useForm({ 
    resolver: yupResolver(ValidationSchemaOption) 
  }) // useForm para Imagen
  let { reset: rTBP, handleSubmit: hTBP, register: regTBP, formState: { errors: eTBP }, control: cTBP, setValue: svTBP, getValues } = useForm({ 
    resolver: yupResolver(ValidationSchema)
  }) // useForm para Banner
  const {fields: fTBP, append: aTBP, remove: rmvTBP, update: updTBP, move: mvTBP} = useFieldArray({ 
    name: 'images', control: cTBP 
  }) // useFieldArray para administrar campo de images (array) del Banner
  const {fields: fOptTBP, append: aOptTBP, remove: rmvOptTBP, update: updOptTBP, move: mvOptTBP} = useFieldArray({ 
    name: 'options', control: cTBP 
  }) // useFieldArray para administrar campo de opciones (array) del Banner

  /* Funciones para editar el banner */
  const mutation = useMutation(saveBannerMultipleLinks, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error')
      } else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })
  const onAddBanner = (params) => {
    setIsLoading(true)
    mutation.mutate(params)
  }

  /* Manejador para cerrar el dialogo limpiando data */
  const handleClose = () => { handleCancelEditImage(); setDataInstanceData({}); rTBP(); svTBP('images', []); close(); }

  /* Manejador para administrar el estado de las instancias (Banner / Image) */
  const handleChangeDataInstances = (instance, field, value) => { 
    switch (instance) {
      case "image":
        setDataInstanceImage({...dataInstanceImage, [field]: value}) 
        break;
      case "option":
        setDataInstanceOption({...dataInstanceOption, [field]: value}) 
        break;
      case "general":
        setDataInstanceData({...dataInstanceData, [field]: value})
        if(field === "real_state_developments") { svTBP(field, value) }
    }
  }

  /* Manejadores de acciones para imagenes del banner */
  const handleCancelEditImage = () => { setDataInstanceImage({}); reset(schemaBanner); setMode(true); setShowForm(false) }
  const handlerAddImage = (params) => {
    aTBP(params)
    reset(schemaBanner)
    setDataInstanceImage(schemaBanner)
    handleCancelEditImage()
  }
  const handleLoadEditImage = (position) => { 
    setMode(false)
    setValue("image", fTBP[position].image)
    setValue("view", fTBP[position].view)
    setDataInstanceImage({ image: fTBP[position].image, view: fTBP[position].view });
    setEditPosition(position)
    setShowForm(true)
  }
  const handlerEditImage = () => { updTBP(editPosition, dataInstanceImage); handleCancelEditImage() }
  const handleRemoveImage = (position) => { rmvTBP(position) }
  const handlerChangePositionImage = (direction, position) => { mvTBP(position, direction === 'up' ? position - 1 : position + 1) }

  /* Manjeadores de acciones de Image Manager */
  const handleOpenImageManager = () => { setImageManagerModalOpen(true) }  
  const handleCloseImageManager = (prop) => { setImageManagerModalOpen(prop) }
  const handleFieldImageManager = (prop) => { setDataFieldImageManager(prop) }
  const handleSelectImageManager = (url) => {
    setDataInstanceImage({...dataInstanceImage, [dataFieldImageManager]: url})
    setValue(dataFieldImageManager, url)
  }

  /* Manejadores de acciones para opciones del banner */
  const handleCancelEditOption = () => { setDataInstanceOption({}); rOPT(schemaOption); setMode(true); setShowFormOption(false) }
  const handlerAddOption = (params) => {
    aOptTBP(params)
    rOPT(schemaOption)
    setDataInstanceOption(schemaOption)
    handleCancelEditOption()
  }
  const handleLoadEditOption = (position) => { 
    setMode(false)
    setValue("name", fOptTBP[position].name)
    setValue("link", fOptTBP[position].link)
    setDataInstanceOption({ name: fOptTBP[position].name, link: fOptTBP[position].link });
    setEditPosition(position)
    setShowFormOption(true)
  }
  const handlerEditOption = () => { updOptTBP(editPosition, dataInstanceOption); handleCancelEditOption() }
  const handleRemoveOption = (position) => { rmvOptTBP(position) }
  const handlerChangePositionOption = (direction, position) => { mvOptTBP(position, direction === 'up' ? position - 1 : position + 1) }

  /* Componente de acciones de cards de imagenes */
  const Actions = ({ index, totalItems }) => ( <>
    <IconButton aria-label="edit" onClick={ () => handleLoadEditImage(index) }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => handleRemoveImage(index) }> <DeleteIcon color='error'/> </IconButton>
    <div>
    {index !== 0 && <IconButton aria-label="up-position" onClick={ () => handlerChangePositionImage("up", index) }> <ArrowUpwardIcon /> </IconButton>}
    {index !== totalItems - 1 && <IconButton aria-label="down-position" onClick={ () => handlerChangePositionImage("down", index) }> <ArrowDownwardIcon /> </IconButton> }
    </div>
  </> )

  /* Componente de acciones de opciones */
  const ActionsOptions = ({ index, totalItems }) => ( <>
    <IconButton aria-label="edit" onClick={ () => handleLoadEditOption(index) }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => handleRemoveOption(index) }> <DeleteIcon color='error'/> </IconButton>
    <div>
    {index !== 0 && <IconButton aria-label="up-position" onClick={ () => handlerChangePositionOption("up", index) }> <ArrowUpwardIcon /> </IconButton>}
    {index !== totalItems - 1 && <IconButton aria-label="down-position" onClick={ () => handlerChangePositionOption("down", index) }> <ArrowDownwardIcon /> </IconButton> }
    </div>
  </> )

  /* Drag and Drop (Orden de imagenes arrastrando y soltando) */
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = getValues("images");
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    svTBP('images', updatedList)
  }

  /* Drag and Drop (Orden de options arrastrando y soltando) */
  const handleDropOptions = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = getValues("images");
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    svTBP('images', updatedList)
  }
 
  return ( <>
    <FullScreenDialog open={ open } changeOpen={ handleClose } title="Agregar banner" actions={ <>
      { !isLoading ? <IconButton autoFocus color="inherit" onClick={ hTBP(onAddBanner) } aria-label="save"> <SaveIcon /> </IconButton> : <CircularProgress /> }
    </> }>
      <div className={`admin-banners-basic-actions`}>
        <div>
          <GeneralDataForm dataInstance={dataInstanceData}
            formAttributes={ { register: regTBP, errors: eTBP } }
            changeDataInstance={handleChangeDataInstances} />
        </div>
        <div>
          { showFormOption ?
            <OptionForm mode={ mode } dataInstance={ dataInstanceOption } 
              changeDataInstance={ handleChangeDataInstances } 
              formAttributes={ { register: regOPT, errors: eOPT, submitValidation: hOPT } }
              actions = { { cancelAction: handleCancelEditOption, editAction: handlerEditOption, saveAction: handlerAddOption } }
            />
          : <>
          <div className="admin-banners-title-actions">
            <div><Typography variant="h6" gutterBottom>Opciones</Typography></div>
            <div><IconButton autoFocus color="inherit" onClick={ () => setShowFormOption(true) } aria-label="add"> <AddIcon /> </IconButton></div>
          </div><br />
          <DragDropContext onDragEnd={handleDropOptions}>
            <Droppable droppableId="droppableOptions">
              { (provided) => (
                <Grid container spacing={2} className="droppableOptions" {...provided.droppableProps} ref={provided.innerRef}>
                { (fOptTBP.length > 0) ? (
                fOptTBP.map((item, i) => (
                  <Draggable key={item.id} draggableId={item.id} index={i}>
                    {(provided) => (
                      <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                        <ImageLeftCard description={<div>{item.name} <br/> {item.link}</div>} actions={ <ActionsOptions index={ i } totalItems={ fOptTBP.length } /> } />
                      </Grid>
                    )}
                  </Draggable>
                )) ) : <Grid item xs={12}> <Typography variant="inherit">No hay opciones agregadas</Typography> </Grid> }
                </Grid>
              ) }
            </Droppable>
          </DragDropContext>
          </> }
        </div>
        <div>
          { showForm ?
            <ImageForm mode={ mode } images={ fTBP } dataInstance={ dataInstanceImage } 
              changeDataInstance={ handleChangeDataInstances } 
              formAttributes={ { register: register, errors: errors, submitValidation: handleSubmit } }
              imageManager={ { setField: handleFieldImageManager, open: handleOpenImageManager  } }
              actions = { { cancelAction: handleCancelEditImage, editAction: handlerEditImage, saveAction: handlerAddImage } }
            />
          : <>
          <div className="admin-banners-title-actions">
            <div><Typography variant="h6" gutterBottom>Imagenes</Typography></div>
            <div><IconButton autoFocus color="inherit" onClick={ () => setShowForm(true) } aria-label="add"> <AddIcon /> </IconButton></div>
          </div><br />
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="droppable">
              { (provided) => (
                <Grid container spacing={2} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                { (fTBP.length > 0) ? (
                fTBP.map((item, i) => (
                  <Draggable key={item.id} draggableId={item.id} index={i}>
                    {(provided) => (
                      <Grid item xs={12} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                        <ImageLeftCard image={item.image} description={item.view} actions={ <Actions index={ i } totalItems={ fTBP.length } /> } />
                      </Grid>
                    )}
                  </Draggable>
                )) ) : <Grid item xs={12}> <Typography variant="inherit">No hay imagenes agregadas</Typography> </Grid> }
                </Grid>
              ) }
            </Droppable>
          </DragDropContext>
          </> }
        </div>
      </div>
    </FullScreenDialog>
    <MediaModal open={imageManagerModalOpen} setOpen={handleCloseImageManager} mediaSelected={handleSelectImageManager} path={`banners`} />
  </> )
}