import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from 'reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from 'context/AuthContext'

const queryClient = new QueryClient()

//{/*<React.StrictMode></React.StrictMode>,*/}
ReactDOM.render(
  <AuthProvider>
    <QueryClientProvider client={ queryClient }>
      <App />
    </QueryClientProvider>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
