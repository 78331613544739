import { forwardRef } from "react";
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'

const Transition = forwardRef(function Transition(props, ref) {
return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, changeOpen, title, children, actions }) {
  return (
    <Dialog 
      fullScreen
      open={open}
      TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative', position: "sticky" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={changeOpen} aria-label="close" >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, fontSize: 18 }} variant="h6" component="div"> {title} </Typography>
          { actions }
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  )
}