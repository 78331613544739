import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  description: Yup.string().required("Campo requerido"),
  link: Yup.string().required("Campo requerido"),
  images: Yup.array().of(
    Yup.object().shape({
      image: Yup.string().required("Campo requerido"),
      view: Yup.string().typeError("Campo requerido").required("Campo requerido")
    })
  ),
  show_in_home: Yup.boolean().required("Campo requerido"),
  show_in_home_alttahomes: Yup.boolean().required("Campo requerido"),
  show_in_home_heroes: Yup.boolean().required("Campo requerido"),
  show_in_home_mihogar: Yup.boolean().required("Campo requerido"),
  real_state_developments: Yup.array().of(Yup.string()),
  active: Yup.boolean().required("Campo requerido"),
})

// Image gallery validation
export const ValidationSchemaImage = Yup.object().shape({
  image: Yup.string().required("Campo requerido"),
  view: Yup.string().typeError("Campo requerido").required("Campo requerido")
})