import * as Yup from 'yup'

export const ValidationSchemaStore = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  email: Yup.string().email().required("Campo requerido"),
  password: Yup.string().required("Campo requerido"),
  role: Yup.string().required("Campo requerido"),
  permitions: Yup.array().of(Yup.string()).nullable(),
  developments: Yup.array().of(Yup.string()).nullable(),
  active: Yup.boolean().required("Campo requerido"),
})

export const ValidationSchemaUpdate = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  password: Yup.string().nullable(),
  role: Yup.string().required("Campo requerido"),
  permitions: Yup.array().of(Yup.string()).nullable(),
  developments: Yup.array().of(Yup.string()).nullable(),
  active: Yup.boolean().required("Campo requerido"),
})